<template>
  <MenuHeaderComponentVue :isAnyOther="true" />
  <div class="fixed inset-0 z-10 w-screen h-screen">
    <!-- Fondo Amarillo -->
    <div class="w-full h-full bg-contain" :class="getBackgroundStyle"></div>
    <!-- Background nubes -->
    <div class="absolute top-0 z-0 w-full h-full">
      <div class="w-full h-2/12"></div>
      <div class="w-full h-10/12 cloudmiddle"></div>
    </div>
    <!-- Contenedor -->
    <div @click="onClickPlay" class="fixed inset-0 top-0 w-full h-full">
      <div class="h-1/12"></div>
      <!-- TituloCurve -->
      <div class="w-full h-2/12 traslate flex justify-center items-center">
        <p
          id="titleGameCurved"
          class="
            text-white text-xl
            sm:text-3xl
            md:text-4xl
            xl:text-5xl
            2xl:text-8xl
            md:tracking-wide
            font-normal
            text-center
            transform
            sm:-translate-y-8
            text-shadow
            tracking-norma
          "
        >
          {{ getLeveTwoSelected.title }}
        </p>
      </div>

      <!-- Img Zac -->
      <div
        class="
          invisible
          w-full
          h-1/12
          flex
          transform
          -translate-y-1/3
          justify-center
        "
      >
        <img class="object-cover" src="../../assets/animals/zacSm.png" alt="" />
      </div>
      <!-- Parrafo clickeable/ -->
      <div
        @click="onClickText"
        class="
          w-full
          h-2/12
          md:h-2/12
          xl:h-1/12
          flex
          justify-center
          items-center
        "
      >
        <div class="mt-5 sm:mt-16 rounded-full shadow-background-circle">
          <div
            class="
              w-52
              h-52
              sm:w-52 sm:h-52
              md:w-64 md:h-64
              lg:w-72 lg:h-72
              xl:w-96 xl:h-96
              2xl:w-104 2xl:h-104
              p-5
              background-circle
              rounded-full
              flex flex-col
              justify-center
              items-center
            "
          >
            <div
              class="
                h-full
                w-full
                p-2
                flex
                justify-center
                items-center
                transform
              "
            >
              <p
                class="
                  font-normal
                  text-center text-white text-sm
                  mdSM
                  md:text-xl
                  xl:text-2xl
                  2xl:text-6xl
                  text-shadow
                "
              >
                {{ getLeveTwoSelected.textEntry }}
                <br />
                <br />
                ¡Ayúdame a encontrar a mis amigos y a los distintos objetos!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-center relative">
        <!-- TODO: aqui esta solo falta la referencia de el juego elegido para remplazar ese 0 -->
        <img
          class="2xl:w-full absolute -bottom-50vh xl:-bottom-60vh"
          :src="getLeveTwoSelected.background"
          alt=""
        />
      </div>
      <!-- Fondo anterior no dinamico -->
      <!-- <div
      class="w-full z-10 h-5/12 md:h-5/12 xl:h-6/12 2xl:h-8/12 md:bg-top lg:bg-center xl:bg-center parque-diversiones"
    ></div> -->
    </div>
  </div>
</template>

<script>
// import ArcText from "arc-text";
import { mapGetters } from "vuex";
import MenuHeaderComponentVue from "../../components/header/MenuHeaderComponent.vue";
import router from "../../router";
export default {
  data() {
    return {
      // windowWidth: window.innerWidth,
      // windoWHeight: window.innerHeight,

      isClickText: false,
    };
  },
  components: {
    MenuHeaderComponentVue,
  },
  computed: {
    ...mapGetters(["getBackgroundStyle", "getLeveTwoSelected", "getGameInfo"]),
  },
  methods: {
    onClickText() {
      this.isClickText = true;
    },
    onClickPlay() {
      router.push("/game-two");
    },
  },
    mounted () {
    if(!this.getLeveTwoSelected.title){
      router.push("/")
    }
  },
};
</script>

<style>
.parque-diversiones {
  background-image: url("../../assets/fondos/parque.png");
  background-size: cover;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .xl\:h-6\/12 {
    height: 55% !important;
  }
}

/* .background-cards-gametwo-entry{
  background: linear-gradient(153.43deg, #FCD536 16.67%, #FFB636 100%);
box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
border-radius: 20px;
} */
/* .rotate {
  transform: scaleX(-1);
} */
</style>