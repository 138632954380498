<template>
  <div class="transform
  translateCircleGame
    
    
      flex flex-col
      z-0
      justify-evenly
      items-center
      w-full
      h-full">
      <div class="sm:mt-24 md:mt-16  rounded-full shadow-background-circle ">

     <div class=" 
            w-52
            h-52
            sm:w-52 sm:h-52
            md:w-64 md:h-64
            lg:w-72 lg:h-72
            xl:w-96 xl:h-96
            2xl:w-104 2xl:h-104
            p-5
            background-circle
            rounded-full
            flex flex-col
            justify-center
            items-center">
          
          <img class="object-cover w-28 sm:w-52 md:w-48 lg:w-48 xl:w-60 2xl:w-104" :src="levelData.img" alt="">
          
    </div> 
      </div>
          <p class="text-center
       
        w-full
        text-white
        font-normal
        text-xl
        sm:text-sm
        
        md:text-2xl
        2xl:text-6xl
        text-shadow">{{levelData.title}}</p>
  </div>
</template>

<script>
export default {
    props: {
        levelData: {            
            default: {},
            required: true,
        },
    },
    methods: {
    },
}
</script>

<style>
/* .background-cards-games{
  background-image: url('../../assets/fondos/cardbg.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
} */
</style>