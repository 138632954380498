import router from "../../router";

export default {
  state: {
    GameInfo: {},

    backgroundStyle: "bg-gradient-to-b from-8fd9ff to-0ab2ff",

    ageSelected: "",
    entryCero:true,
    worldSelected: "",
  },
  mutations: {
    setEntryCero(state,payload){
      state.entryCero = payload
    },
    setGameInfo(state, payload) {
      state.GameInfo = payload;
    },
    setBackgroundStyle(state, background) {
      state.backgroundStyle = background;
    },
    setAgeSelected(state, age) {
      state.ageSelected = age;
    },
    setWorldSelected(state, world) {
      state.worldSelected = world;
    },
  },
  actions: {
    onchangeEntryCero({commit}){
      commit('setEntryCero',false)
    },
    // this method gets  data from gamesDataMenus -> utils/dataMenus.js
    onSetGameInfo({ commit }, DataGameOpend) {
      commit("setGameInfo", DataGameOpend);
      router.push("/instrucciones");
    },
    onSetBackgoundStyle({ commit }, background) {
      commit("setBackgroundStyle", background);
    },
    onSelectAge({ commit }, age) {
      commit("setAgeSelected", age);
    },
    onSetWorld({ commit }, world) {
      commit("setWorldSelected", world)
    }
  },
  getters: {
    getEntryCero: state => state.entryCero,
    getGameInfo: (state) => state.GameInfo,
    getBackgroundStyle: (state) => state.backgroundStyle,
    getAgeSelected: (state) => state.ageSelected,
    getWorld: (state) => state.worldSelected,
  },
};
