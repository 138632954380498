<template>
  <MenuHeaderComponentVue :isAnyOther="true" />
  <div class="fixed inset-0 w-screen h-screen" :class="getBackgroundStyle">
    <div class="fixed z-50 inset-0 w-screen h-screen">
      <!-- Backgroud cloud -->
      <div class="absolute top-0 z-0 w-full h-full">
        <div class="w-full h-2/12"></div>
        <div class="w-full h-10/12 object-fill cloudmiddle"></div>
        <div class="h-1/12">
          <div class="w-full h-full flex flex-col justify-between"></div>
        </div>
      </div>

      <div class="fixed inset-0 w-full h-full z-10 transform translate-y-2 titletranslate">
        <div class="h-1/12"></div>
        <!-- TITULO -->
        <div class="h-1/12 ">
          <p
            id="curve"
            class="
              z-30
              
              text-white
              sm:text-2xl
              md:text-3xl
              xl:text-4xl
              2xl:text-8xl
              font-normal
              text-center text-shadow
              tracking-normal
            "
          >
            ¿Qué vamos a jugar hoy?
          </p>
        </div>
        <!-- SWIPER -->
        <div
          class="
            games
            h-8/12
            heigth-Bajo
            z-50
            flex
            justify-center
            items-center
            sm:-mt-20
            md:-mt-20
            xl:-mt-28
          "
        >
          <Swiper
            class="swiper w-full h-full flex justify-center"
            :loop="true"
            :slidesPerView="'auto'"
            :navigation="true"
            :pagination="{ clickable: true }"
            :spaceBetween="90"
            :centeredSlides="true"
            @slideChange="change"
          >
            <swiper-slide
              class="w-9/12 h-full"
              v-for="(game, i) in getFilteredGamesPerAge"
              :key="i"
            >
              <SelectorGameCardsVue :gameData="game" />
            </swiper-slide>
          </Swiper>
        </div>
      </div>
    </div>
    <!-- MUNDO -->
    <div class="mundoHidden absolute z-0 w-full h-full">
      <div class="w-full h-1/2 xl:h-1/5"></div>
      <img
        class="
          w-full
          xl:h-full
          transform
          -translate-y-15
          xl:-translate-y-0
          md:bg-top
          lg:bg-center
          xl:bg-top
        "
        :src="getWorld"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MenuHeaderComponentVue from "../components/header/MenuHeaderComponent.vue";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import "swiper/swiper-bundle.css";
import SelectorGameCardsVue from "../components/cards/SelectorGameCards.vue";
import { gamesDataMenus } from "../utils/dataMenus";
// import ArcText from "arc-text";
import SwiperCore, { Pagination, Navigation } from "swiper";
import router from '../router';

SwiperCore.use([Pagination, Navigation]);
export default {
  data() {
    return {
      gamesDataMenus: gamesDataMenus,
      // windowWidth: window.innerWidth,
      // windoWHeight: window.innerHeight,
    };
  },
  components: {
    MenuHeaderComponentVue,
    Swiper,
    SwiperSlide,
    SelectorGameCardsVue,
  },
  computed: {
    ...mapGetters(["getBackgroundStyle", "getAgeSelected", "getWorld"]),
    getFilteredGamesPerAge() {
      let gamesInThisAge = [];
      for (const game in this.gamesDataMenus) {
        if (
          this.gamesDataMenus[game].availableForAge === this.getAgeSelected ||
          this.gamesDataMenus[game].availableForAge === "all"
        ) {
          gamesInThisAge.push(this.gamesDataMenus[game]);
        }
      }
      return gamesInThisAge;
    },
  },
  methods: {
    nextCard() {},
  },
  mounted () {
    if(this.getAgeSelected === ""){
      router.push("/")
    }
  },

  //   mounted() {
  //     const arcText = new ArcText(document.getElementById("curve"));

  //     let size = 0;
  // this.windoWHeight == 1024 && this.windowWidth == 768 ? (size = 280) :
  //     this.windowWidth <= 374
  //       ? (size = 200)
  //       : this.windowWidth < 820
  //       ? (size = 210)
  //       : this.windowWidth < 1025
  //       ? (size = 300)
  //       : this.windowWidth > 1025
  //       ? (size = 700)
  //       : (size = 600);

  //     arcText.arc(size);
  //     window.onresize = () => {
  //       this.windowWidth = window.innerWidth; // asigno a la propiedad el valor de el ancho de la ventana
  //       this.windoWHeight = window.innerWidth;
  //     };
  //   },
};
</script>

<style>
.worldGame {
  background-image: url("../../public/img/worlds/worldGame.png");
  background-size: cover;
  background-repeat: no-repeat;
}
</style>