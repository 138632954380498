<template>
  <MenuHeaderComponentVue :isAnyOther="true" />
  <div class="fixed inset-0 w-screen h-screen" :class="getBackgroundStyle">
    <div class="absolute top-0 z-0 w-full h-full">
      <div class="w-full h-2/12"></div>
      <div class="w-full h-10/12 object-fill cloudmiddle"></div>
      <div class="h-1/12">
        <div class="w-full h-full flex flex-col justify-between"></div>
      </div>
    </div>
    <div class="fixed inset-0 w-full h-full z-10">
      <div class="w-full h-2/12 flex justify-center items-center">
        <p
          class="
            text-2xl
            md:text-3xl
            text-white
            font-normal
            transform
            translate-y-6
          "
        >
          {{ getLeveSelected.titleGameInside }}
        </p>

        <audio
          id="audio"
          :src="getOptionSelected.audio"
          type="audio/mpeg"
          autoplay
        ></audio>
      </div>
      <div class="grid grid-cols-2 gap-6 h-8/12 overflow-y-auto">
        <div
          v-for="(option, x) in getLeveSelected.options"
          :key="x"
          class="h-full w-full flex justify-center items-center"
        >
          <div
            @click="onSelectOptionCards(option, option.title)"
            class="
              w-full
              min-h-140
              h-full
              rounded-full
              flex
              justify-center
              items-center
            "
            :class="
              getOptionSelected.title === option.title
                ? 'object-circle-acent-bg'
                : 'object-circle-bg'
            "
          >
            <div class="flex justify-center items-center">
              <img
                :class="
                  getLeveSelected.title === 'Familia' ||
                  getLeveSelected.title === 'Animales'
                    ? 'h-full'
                    : 'h-full'
                "
                class="max-h-24 object-contain"
                :src="option.miniature"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="w-full h-2/12 flex flex-col items-center justify-center">
        <div class="bg-no-repeat">
          <img
            v-if="getLeveSelected.titleGameInside === 'Animales'"
            class="transform translate-y-full xl:hidden"
            src="../../assets/iconos/fleachaAbajo.png"
            alt=""
            fill="white"
          />
        </div>
        <img
          class="h-full w-full object-contain"
          src="../../assets/fondos/mundobassegameone.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MenuHeaderComponentVue from "../../components/header/MenuHeaderComponent.vue";
import router from "../../router";
export default {
  components: {
    MenuHeaderComponentVue,
  },
  data() {
    return {
      audio: {},
      zacText: true,
    };
  },
  unmounted() {
    this.onSelectOption({ option: {}, textChange: "" });
  },
  computed: {
    ...mapGetters([
      "getGameInfo",
      "getLeveSelected",
      "getBackgroundStyle",
      "getOptionSelected",
    ]),
  },
  methods: {
    ...mapActions(["onSelectOption"]),
    onSelectOptionCards(option, textChange) {
      this.onSelectOption({ option, textChange });
      this.audio.play();
    },
    goBack() {
      router.replace("/leve-selector");
      // this.onSelectOption({ option: {}, textChange: "" }); esto debe de un el unmounted
    },
    changeTextZac() {
      this.zacText = false;
    },
  },
  mounted() {
    this.audio = document.getElementById("audio");
    if(!this.getLeveSelected.titleGameInside){
      router.push('/')
    }
  },
};
</script>

<style>
.background-opcion {
  background: linear-gradient(153.43deg, #ffb636 16.67%, #ff9420 100%);
  /* background-image: url('../assets/animals/zebrainicio.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center; */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}
.bg-blur {
  background: radial-gradient(
    97.57% 210.75% at 0.9% 2.98%,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  backdrop-filter: blur(42px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 15px;
}

.object-circle-bg {
  background-image: url("../../assets/fondos/circulos.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.object-circle-acent-bg {
  background-image: url("../../assets/fondos/circulosAcent.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
</style>