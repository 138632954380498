<template>
  <div
    class="
      fixed
      inset-0
      w-screen
      h-screen
      cloud1
      bg-gradient-to-b
      from-8fd9ff
      to-0ab2ff
      flex
      justify-center
      items-center
    "
  >
    <div
      class="
        fixed
        inset-0
        z-20
        w-full
        h-full
        flex flex-col
        justify-center
        items-center
      "
    >
      <div
        class="w-3/4 transform translate-y-1/4 flex justify-center items-center"
      >
        <img
          class="object-contain w-20 xl:w-32 h-full"
          src="../assets/iconos/biogen.png"
          alt=""
        />
      </div>

      <!-- Background mundo -->
      <div
        class="
          w-full
          h-full
          worldinit
          flex flex-col
          transform
          justify-between
          items-center
        "
      >
        <div
          class="
            w-3/4
            h-2/6
            absolute
            bottom-10
            md:bottom-10
            flex flex-col
            justify-center
            items-center
          "
        >
          <button
            @click="goNext"
            class="
              w-full
              lg:w-4/6
              h-4/6
              lg:h-full
              rounded-full
              text-white
              font-bold
              text-2xl
              lg:text-3xl
              2xl:text-7xl
              btn-generic
            "
          >
            Entrar
          </button>
          <p
            class="
              fondoTransparent
              transform
              translate-y-1/3
              text-shadow
              rounded-xl
              z-50
              text-xs
              xl:text-lg
              py-2
              px-4
            "
          >
            Biogen-152319
          </p>
          <p
            class="
              fondoTransparent
              transform
              translate-y-1/3
              text-shadow
              rounded-xl
              z-50
              font10
              xl:text-lg
              py-2
              px-4
            "
          >
            "El material contenido es sólo para uso de profesionales de la
            salud. Prohibida la reproducción total o parcial. Derechos
            reservados. Biogen México"
          </p>
        </div>
        <!-- <div class="flex justify-center">
          <div
            class="
              fixed
              flex flex-col
              justify-center
              items-center
              text-center
              bottom-4
              fredoka
              tracking-wider
            "
          >
            <div class="mb-1" style="z-index: 60">
              <p
                class="
                  fondoTransparent
                  transform
                  translate-y-1/3
                  text-shadow
                  rounded-xl
                  z-50
                  text-xs
                  xl:text-lg
                  py-2
                  px-4
                "
              >
                Biogen-152319
              </p>
            </div>
            <div class="" style="z-index: 60">
              <p
                class="
                  fondoTransparent
                  transform
                  translate-y-1/3
                  text-shadow
                  rounded-xl
                  z-50
                  font10
                  xl:text-lg
                  py-2
                  px-4
                "
              >
                "El material contenido es sólo para uso de profesionales de la
                salud. Prohibida la reproducción total o parcial. Derechos
                reservados. Biogen México"
              </p>
            </div>
            <router-view />
          </div>
        </div> -->
      </div>
    </div>
  </div>
  <!-- <div class="flex justify-center">
    <div
      class="
        fixed
        flex flex-col
        justify-center
        items-center
        text-center
        bottom-4
        fredoka
        tracking-wider
      "
    >
      <div class="mb-1" style="z-index: 60">
        <p
          class="
            fondoTransparent
            transform
            translate-y-1/3
            text-shadow
            rounded-xl
            z-50
            text-xs
            xl:text-lg
            py-2
            px-4
          "
        >
          Biogen-152319
        </p>
      </div>
      <div class="" style="z-index: 60">
        <p
          class="
            fondoTransparent
            transform
            translate-y-1/3
            text-shadow
            rounded-xl
            z-50
            font10
            xl:text-lg
            py-2
            px-4
          "
        >
          "El material contenido es sólo para uso de profesionales de la salud.
          Prohibida la reproducción total o parcial. Derechos reservados. Biogen
          México"
        </p>
      </div>
      <router-view />
    </div>
  </div> -->
</template>

<script>
import router from "../router/index";

export default {
  methods: {
    goNext() {
      router.push("/privacy");
    },
  },
};
</script>

<style>
.font10 {
  font-size: 0.5rem;
}
.cloud1 {
  background-image: url("../assets/fondos/cloud1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.mundodezac {
  background-image: url("../assets/letras/mundozac.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.btn-generic {
  background-image: url("../assets/btns/btn-generic.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.zebrainicial {
  background-image: url("../assets/animals/zac.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.worldinit {
  margin-top: 0;
  background-image: url("../assets/fondos/mundoZacJoined.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 350px) and (max-height: 500px) {
  .worldinit {
    margin-top: 0;

    background-image: url("../assets/fondos/mundoZacIpad.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}
@media (min-width: 700px) and (min-height: 900px) {
  .worldinit {
    margin-top: 0;

    background-image: url("../assets/fondos/mundoZacIpad.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}
/* 
@media (max-width: 320px) and (max-height: 444px) {
  .worldinit {
    margin-top: 0;
    background-image: url("../assets/fondos/mundoZacSm.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

@media (min-width: 810px) and (min-height: 995px) {
  .worldinit {
    margin-top: 0;

    background-image: url("../assets/fondos/mundoZacIpad.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

@media (max-height: 375px) {
  .worldinit {
    margin-top: 0;
    background-image: url("../assets/fondos/mundoZacJoined.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

@media (max-height: 390px) {
  .worldinit {
    margin-top: 0;
    background-image: url("../assets/fondos/mundoZacJoinedMd.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

@media (min-height: 1024px) and (min-width: 768px) {
  .worldinit {
    margin-top: 0;
    background-image: url("../assets/fondos/mundoZacJoinedIpad.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
} */

.rectangleBlur {
  background: radial-gradient(
    97.57% 210.75% at 0.9% 2.98%,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 0, 0, 0) 100%
  );
  border-radius: 15px;
}
.fondoTransparent {
  background-color: #ffffff3b;
  backdrop-filter: blur(2px);
  color: rgb(201, 201, 201);
}
</style>