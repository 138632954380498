<template>
  <div
    class="
      w-screen
      h-screen
      bg-gradient-to-b
      from-0ab2ff
      to-white
      flex
      justify-center
      items-center
    "
  >
    <div class="w-3/4 h-3/5 flex flex-col justify-center items-center">
      <div class="w-1/3">
        <img class="" src="../../assets/letras/mundozac.png" alt="" />
      </div>
      <div class="bg-white flex flex-col  px-16 py-8 rounded-xl">
        <div>
          <p class="text-lg text-black text-center p-4">Nombre de usuario</p>
          <input
            v-model="userName"
            class="bg-gray-100 p-2 rounded-md border1px border-black"
            type="text"
          />
        </div>

        <div>
          <p class="text-lg text-black text-center p-4">password</p>
          <input
            v-model="password"
            class="bg-gray-100 p-2 rounded-md border1px border-black"
            type="password"
          />
        </div>
        <button
          @click="entry"
          class="bg-blue-600 animate-pulse rounded-md text-white mt-4 py-2 px-4"
        >
          Entrar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      password: "",
      userName: "",
    };
  },
  methods: {
    ...mapActions(["onLogin"]),
    entry() {
      this.password = this.password.trim();
      this.userName = this.userName.trim();
      this.onLogin({ password: this.password, userName: this.userName });
    },
  },
};
</script>

<style>
</style>