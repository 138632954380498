<template>
  <div class="fixed w-screen h-screen bg-gradient-to-b from-4AA4F2 to-A2DAEB">
    <!-- Contenedor -->
    <div class="h-full z-0 w-full bg-pc-alert">
      <div class="h-full z-0 w-full flex flex-col justify-center tierra-aves">
        <!-- <div class="w-full flex justify-center h-1/12"></div> -->
        <div class="w-full flex justify-center h-2/12">
          <img src="../../assets/letras/mundozac.png" alt="" />
        </div>
        <div class="w-full h-2/12 flex justify-evenly items-center">
          <div>
            <img
              class="invisible transform -translate-y-2/4"
              src="../../assets/objetos/sol.png"
              alt=""
            />
          </div>
          <div
            class="
              h-10/12
              w-2/3
              blur-pc-alert
              border border-white
              flex
              justify-center
              items-center
            "
          >
            <p class="text-center px-8 text-FE8400 text-lg xl:text-3xl">
              ¡Oh, oh! Este juego solo está disponible para smartphones y
              tablets.
            </p>
          </div>
          <div>
            <img
              class="transform -translate-y-2/4"
              src="../../assets/objetos/sol.png"
              alt=""
            />
          </div>
        </div>
        <div class="w-full h-2/12 flex justify-center items-center">
          <div
            class="
              h-10/12
              w-3/4
              bg-pc-alert-orange
              flex
              justify-center
              items-center
            "
          >
            <p class="text-center p-4 text-white text-lg xl:text-3xl">
              Escanea el código QR con la cámara de tu celular/tablet y
              descubramos el Mundo juntos
            </p>
          </div>
        </div>
        <div class="w-full flex flex-col justify-center items-center h-4/12 ">
          <div class="flex justify-center mb-0 transform translate-y-2 z-30">
            <img
              class="w-1/2 rotate-img"
              src="../../assets/animals/zacSm.png"
              alt=""
            />
          </div>
          <div class="w-52 h-auto flex justify-center items-center mt-0 z-0">
            <div class="p-5 fondoTransparent rounded-2xl borde-Amarillo">

            <img class="w-full border-2 rounded-2xl border-blue-900" src="../../assets/objetos/qr.png" alt="" />
            </div>
          </div>
        </div>
        <div class="w-full h-2/12 flex justify-center items-center">
          <img
            class="object-contain w-20 h-6"
            src="../../assets/iconos/biogenColors.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.bg-pc-alert {
  background-image: url("../../assets/fondos/background-nubes.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}
.tierra-aves {
  background-image: url("../../assets/fondos/tierraAvez.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}
.blur-pc-alert {
  background: radial-gradient(
    97.57% 210.75% at 0.9% 2.98%,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  backdrop-filter: blur(42px);
  border-radius: 20px;
}
.bg-pc-alert-orange {
  background: linear-gradient(153.43deg, #fecb00 16.67%, #ffb636 100%);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
}

.borde-Amarillo{
  border:2px solid #fecb00;
}
</style>