<template>
  <div class=" w-full h-full " :key="'1'">
         <div class="w-full h-full" >
      <div class="w-full h-full ">
    <div class="w-full h-10/12 background-game-two" :style="{'background-image': 'url(' + scene.imgBackground + ')'}">
        <div class='w-full h-full grid '>
            <div class='w-full h-full grid grid-cols-10 '>
               <div v-for="x in 200" :key="x" 
                @click="selectPlace(x)"                
                class='w-full h-full container text-xs '>
                 <img v-if="checkIsObjectInPosition(x)" class="absolute h-6 object-cover " src="https://img.icons8.com/color/48/000000/ok--v1.png"/>
                 <!-- {{x}} -->
                </div>
            </div>
        </div>
      </div>
      <!-- Barra puntaje -->
      <div class="w-full h-2/12 bg-FECB00 flex justify-around items-center">
        <div v-for="object,x in scene.objectsInScene" :key="x" class="h-10/12 w-full flex flex-col justify-center  
            items-center  rounded-full ">
            <div class="w-full h-full flex flex-col items-center transform translate-y-4">

            <div class="w-10/12 h-1/2 flex background-object-circles rounded-full justify-center items-center"><img class="h-1/2" :src="object.imgObject" alt=""></div>
            <div v-if="object.numberObjectsFinded!==object.numberOfObjects" class="w-10/12 h-1/2 text-white flex justify-center text-shadow-25 items-center">
            {{object.numberObjectsFinded}}/{{object.numberOfObjects}}</div>
            <div v-else class="w-10/12 h-1/2 text-green-600 flex justify-center text-shadow-25 items-center">
            <img class="h-6" src="https://img.icons8.com/color/48/000000/ok--v1.png"/>
            </div>
            </div>

        </div>
      </div> 
          </div>     
  </div>
     </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import router from '../../router'
export default {
    
    props:{
        scene:{},
        sceneNumber:{},
    },
    created() {
        this.onResetActualLeveGameTwoOnReset()
    },
    methods: {
        ...mapActions(['onFindObject','onResetActualLeveGameTwoOnReset',]),
        checkIsObjectInPosition(numberPosition){
            let isPositionObject = false
            this.scene.objectsInScene.forEach((object)=>{
                object.positions.forEach(objectdata =>{
                    objectdata.position.forEach(number =>{
                        if(number === numberPosition && objectdata.isFinded  && objectdata.boxFinded ===numberPosition  ){
                                isPositionObject = true
                                return true
                        }
                    })
                    // if(objectdata.position === numberPosition && objectdata.isFinded === false ){
                    //     isPositionObject = true
                    //     return true
                    // }                     
                })
            })
            return isPositionObject
        },
        selectPlace(x){
            this.scene.objectsInScene.forEach((objects,positionObject)=>{                
                objects.positions.forEach((object,objectinArray) =>{
                    object.position.forEach(number =>{
                        if(number === x  && object.isFinded === false){
                        this.onFindObject({positionObject:positionObject,object:objectinArray,sceneNumber:this.sceneNumber})
                        this.checkUserWins(this.scene.objectsInScene)
                    }
                    })
                })
            })
        },
        // TODO: optimizar esto :/
        checkUserWins(){
            let numberOfComplete =0;
            let totalsObjects = 0;
            this.getLeveTwoSelected.scenes.forEach((objects)=>{
                objects.objectsInScene.forEach((dataObject)=>{
                    if(dataObject.numberOfObjects === dataObject.numberObjectsFinded){
                        numberOfComplete += 1
                    }
                    totalsObjects += 1
                    
                })
            })
            if(totalsObjects===numberOfComplete){
                router.push('/game-two-win')
            }
            
        }
        //     goBack() {
        //   router.replace("/leve-selector");
        //   // this.onSelectOption({ option: {}, textChange: "" }); esto debe de un el unmounted
      
    // },

    },

// mounted() {
//     if(this.scene.imgBackground === ''){
//       router.push('/')
//     }
//   },


    computed: {
        ...mapGetters(['getLeveTwoSelected'])
    },
}
</script>

<style>
.background-game-two{
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.background-object-circles{
    background-image: url('../../assets/iconos/dobleElipseObjects.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
</style>