<template>
 <MenuHeaderComponentVue :isAnyOther="true"/>
 <div
    class="
      fixed
      inset-0
      w-screen
      h-screen
      bg-gradient-to-b
      from-19e4ec
      to-0da2a3
    "
  >
    <!-- Nubes -->
    <div class="absolute z-10 top-0 w-full h-full cloud-instructions"></div>
    <!-- Contenedor -->
    <div class="fixed inset-0 z-20 top-0 w-full h-full flex flex-col justify-center items-center">
      <!-- Viviendo con AME -->
      <div class="w-full h-2/12 flex justify-center items-end pb-4">
        <p
          id="curve"
          class="
            font-normal
            transform
            translate-y-3/4
            text-white text-shadow
            sm:text-3xl
            md:text-3xl
            xl:text-5xl
            2xl:text-8xl
          "
        >
          Asociaciones Médicas
        </p>
      </div>
      <!-- Contenedor del futuro circulo -->
      <!-- <div class="w-full h-4/12 flex justify-center items-starts-"> -->
       <div class="sm:mt-12 rounded-full shadow-background-circle">
        <div
          class=" w-52
            h-52
            sm:w-52 sm:h-52
            md:w-64 md:h-64
            lg:w-72 lg:h-72
            xl:w-96 xl:h-96
            2xl:w-104 2xl:h-104
            p-5
            background-circle
            rounded-full
            flex flex-col
            justify-center
            items-center
            
          "
        >
       
          <p
            class="
              font-normal
              text-center text-white text-sm
              md:text-base
              xl:text-2xl
              2xl:text-6xl
              text-shadow
              h-full
              transform
              md:translate-y-0
              xl:translate-y-0
              2xl:translate-y-1/4
              traslateN
            "
          >
            ¡Hola! <br />
            <br />
            Esta sección es para cuidadores. Recuerda siempre estar acompañado
            de tu cuidador si deseas seguir. <br />
          </p>

        
        </div>
       </div>
      <!-- </div> -->
      <!-- ¿Desea continuar? -->
      <div class="w-4/6 xl:w-2/4 h-1/12 transform -translate-y-1/4 flex justify-center items-center traslate">
        <img class="" src="../../../public/img/instructions/continuar.png" alt="" />
       
      </div>
      <!-- Contenedor botones -->
      <div class="w-full h-1/12 flex justify-around items-center">
        <div
          @click="goTo('/selector-edad')"
          class="w-20 2xl:w-96 h-14 flex justify-center items-center"
        >
          <img
            class="object-contain"
            src="../../assets/btns/btn-games.png"
            alt=""
          />
        </div>
        <div
          @click="goTo('/medic-options')"
          class="w-20 2xl:w-96 h-14 flex justify-center items-center"
        >
          <img
            class="object-contain"
            src="../../assets/btns/btn-play.png"
            alt=""
          />
        </div>
      </div>
      <!-- Biogen/remplazar por Mamá Zac -->
      <div class="w-full h-4/12 flex justify-center items-center">
        <div class="w-2/4 h-1/6 flex justify-center items-center">
          <img
            class="object-contain 2xl:w-35"
            src="../../assets/animals/parentingImg.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="fixed inset-0 w-screen h-screen  bg-green-perso" >
      <div class="absolute z-10 top-0 w-full h-full cloud-instructions"></div>
      <div class="absolute z-20 top-0 w-full h-full">
          <div class="w-full h-3/12"></div>
          <div class="w-full h-5/12 plants transform -translate-x-28"></div>
          <div class="w-full h-3/12"></div>
      </div>
      <div class="fixed inset-0 z-30 top-0 w-full h-full ">
            <div class="w-full h-3/12  flex justify-center items-center pb-4">
                <p class="font-normal text-298988 text-2xl md:text-3xl">Asociaciones médicas</p> 
            </div>
             <div class="w-full h-3/12  flex justify-center  items-center ">
             <div class="w-11/12 h-full">
             <img class="h-full transform translate-y-2" src="../../assets/personajes/Tableta_PapasHabla.png" alt="">             
             </div>
             </div>
            <div class="w-full h-3/12  flex justify-center items-center ">
               <div class="w-11/12 px-6 h-full flex flex-col justify-center items-center card-middle-living-ame border-2 border-white">
                    <p class="font-normal text-center pt-2 text-298988 text-sm md:text-base">
                       ¡Hola!  <br> <br>Esta sección es para cuidadores.  Recuerda siempre estar acompañado de tu cuidador si deseas seguir. <br> ¿Deseas continuar?
                       </p>
               </div>
            </div>
            <div class="w-full h-2/12  flex justify-around items-center">
                <div @click="goTo('/selector-edad')" class="w-32 md:w-44 h-12 md:h-14  flex justify-center items-center">
                     <img  class="object-contain" src="../../assets/btns/btn-games.png" alt="">
                </div>
                <div @click="goTo('/medic-options')" class="w-32 md:w-44 h-12 md:h-14 flex justify-center items-center">
                    <img class="object-contain" src="../../assets/btns/btn-generic.png" alt="">
                    <p class="absolute text-base md:text-xl text-white">Si, ¡Vamos!</p>
                </div>
            </div>
            <div class="w-full h-1/12  flex justify-center items-center">
                <div class="w-3/4  h-1/6  flex justify-center items-center">
            <img class="object-contain w-20 h-6" src="../../assets/iconos/biogen.png" alt="">
          </div>
            </div>
      </div>
  </div> -->
</template>

<script>
import MenuHeaderComponentVue from '../../components/header/MenuHeaderComponent.vue'
import router from '../../router'
import ArcText from "arc-text";


export default {
     data() {
    return {
      windowWidth: window.innerWidth,
      windoWHeight: window.innerHeight,

    };
  },
    components: {
        MenuHeaderComponentVue
    },
    computed: {
    },
    methods: {
        goTo(url){
            router.push(url)
        }
    },
    mounted() {
    const arcText = new ArcText(document.getElementById("curve"));

    let size = 0;

this.windoWHeight == 1024 && this.windowWidth == 768 ? (size = 290) : 

    this.windowWidth <= 374
      ? (size = 200)
      : this.windowWidth < 820
      ? (size = 210)
      : this.windowWidth < 1025
      ? (size = 300)
      : this.windowWidth > 1025
      ? (size = 700)
      : (size = 600);

    arcText.arc(size);
    window.onresize = () => {
      this.windowWidth = window.innerWidth; // asigno a la propiedad el valor de el ancho de la ventana
      this.windoWHeight = window.innerWidth;

    };
  },

}
</script>

<style>
.bg-green-perso{
    background: linear-gradient(270deg, #C7F492 30.66%, rgba(254, 244, 0, 0.5) 103.79%);
}
.plants {
  background-image: url('../../assets/fondos/Leaves.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
}
</style>