<template>
  <MenuHeaderComponentVue :isAnyOther="true" />
  <div
    class="
      fixed
      inset-0
      w-screen
      h-screen
      bg-gradient-to-b
      from-19e4ec
      to-0da2a3
    "
  >
    <!-- Nubes -->
    <div class="absolute z-10 top-0 w-full h-full cloud-instructions"></div>
    <!-- Contenedor -->
    <div class="fixed inset-0 z-20 top-0 w-full h-full flex flex-col justify-center items-center">
      <!-- Viviendo con AME -->
      <div class="w-full h-2/12 flex justify-center items-end pb-4">
        <p
          id="curve"
          class="
            font-normal
            transform
            translate-y-3/4
            text-white text-shadow
            sm:text-3xl
            md:text-3xl
            xl:text-5xl
            2xl:text-8xl
          "
        >
          Viviendo con AME
        </p>
      </div>
      <!-- Contenedor del futuro circulo -->
      <!-- <div class="w-full h-4/12 flex justify-center items-starts-"> -->
       <div class="sm:mt-12 rounded-full shadow-background-circle">
        <div
          class=" w-52
            h-52
            sm:w-52 sm:h-52
            md:w-64 md:h-64
            lg:w-72 lg:h-72
            xl:w-96 xl:h-96
            2xl:w-104 2xl:h-104
            p-5
            background-circle
            rounded-full
            flex flex-col
            justify-center
            items-center
            
          "
        >
       
          <p
            class="
              font-normal
              text-center text-white text-sm
              md:text-base
              xl:text-2xl
              2xl:text-6xl
              text-shadow
              h-full
              transform
              md:translate-y-0
              xl:translate-y-0
              2xl:translate-y-1/4
              traslateN
            "
          >
            ¡Hola! <br />
            <br />
            Esta sección es para cuidadores. Recuerda siempre estar acompañado
            de tu cuidador si deseas seguir. <br />
          </p>

        
        </div>
       </div>
      <!-- </div> -->
      <!-- ¿Desea continuar? -->
      <div class="w-4/6 xl:w-2/4 h-1/12 transform -translate-y-1/4 flex justify-center items-center traslate">
        <img class="" src="../../../public/img/instructions/continuar.png" alt="" />
       
      </div>
      <!-- Contenedor botones -->
      <div class="w-full h-1/12 flex justify-around items-center">
        <div
          @click="goTo('/selector-edad')"
          class="w-20 2xl:w-96 h-14 flex justify-center items-center"
        >
          <img
            class="object-contain"
            src="../../assets/btns/btn-games.png"
            alt=""
          />
        </div>
        <div
          @click="goTo('/cardsLivingAme')"
          class="w-20 2xl:w-96 h-14 flex justify-center items-center"
        >
          <img
            class="object-contain"
            src="../../assets/btns/btn-play.png"
            alt=""
          />
        </div>
      </div>
      <!-- Biogen/remplazar por Mamá Zac -->
      <div class="w-full h-4/12 flex justify-center items-center">
        <div class="w-3/4 h-1/6 flex justify-center items-center">
          <img
            class="object-contain 2xl:w-35"
            src="../../assets/personajes/Tableta_PapasHabla.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuHeaderComponentVue from "../../components/header/MenuHeaderComponent.vue";
import router from "../../router";
import ArcText from "arc-text";

export default {
  data() {
    return {
      windowWidth: window.innerWidth,
      windoWHeight: window.innerHeight,

    };
  },
  components: {
    MenuHeaderComponentVue,
  },
  computed: {},
  methods: {
    goTo(url) {
      router.push(url);
    },
  },
  mounted() {
    const arcText = new ArcText(document.getElementById("curve"));

    let size = 0;

this.windoWHeight == 1024 && this.windowWidth == 768 ? (size = 280) : 

    this.windowWidth <= 374
      ? (size = 200)
      : this.windowWidth < 820
      ? (size = 210)
      : this.windowWidth < 1025
      ? (size = 300)
      : this.windowWidth > 1025
      ? (size = 700)
      : (size = 600);

    arcText.arc(size);
    window.onresize = () => {
      this.windowWidth = window.innerWidth; // asigno a la propiedad el valor de el ancho de la ventana
      this.windoWHeight = window.innerWidth;

    };
  },
};
</script>

<style>
/* .card-middle-living-ame {
  background: radial-gradient(
    97.57% 210.75% at 0.9% 2.98%,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  backdrop-filter: blur(42px);

  border-radius: 15px;
} */

/* .gradient-border-blue {
  background-image: radial-gradient(
      circle at 100% 100%,
      transparent 12px,
      #ffffff 12px,
      #ffffff 15px,
      transparent 15px
    ),
    linear-gradient(to right, #ffffff, #4baeff00),
    radial-gradient(
      circle at 0% 100%,
      transparent 12px,
      #4baeff00 12px,
      #4baeff00 15px,
      transparent 15px
    ),
    linear-gradient(to bottom, #4baeff00, #4baeff),
    radial-gradient(
      circle at 0% 0%,
      transparent 12px,
      #4baeff 12px,
      #4baeff 15px,
      transparent 15px
    ),
    linear-gradient(to left, #4baeff, #ffffff),
    radial-gradient(
      circle at 100% 0%,
      transparent 12px,
      #ffffff 12px,
      #ffffff 15px,
      transparent 15px
    ),
    linear-gradient(to top, #ffffff, #ffffff);
  background-size: 15px 15px, calc(100% - 30px) 3px, 15px 15px,
    3px calc(100% - 30px);
  background-position: top left, top center, top right, center right,
    bottom right, bottom center, bottom left, center left;
  background-repeat: no-repeat;
} */
</style>