<template>
  <MenuHeaderComponentVue :isAnyOther="true" />
  <div class="fixed inset-0 w-screen h-screen flex justify-center items-center">
    <!-- Ba -->
    <div
      class="w-full h-full bg-blur-15"
      :style="{
        'background-image':
          'url(' + getLeveSelectedGameThree.backgroundImg + ')',
      }"
    ></div>
    <div class="fixed top-0 w-full h-full">
      <!-- <div class="w-full h-1/12 "></div> -->
      <div
        class="
          w-full
          z-50
          h-2/12
          transform
          translate-y-23x
          flex
          justify-center
          items-center
        "
      >
        <img
          class="h-full z-50"
          src="../../assets/animals/ratttyDialog.png"
          alt=""
        />
        <div
          class="
            h-full
            w-2/4
            ratty-dialog
            text-center text-white text-xl
            sm:text-sm
            md:text-xl
            xl:text-4xl
            2xl:text-6xl
            text-shadow
            flex
            justify-center
            items-center
          "
        >
          ¡Increíble!
        </div>
      </div>
      <div class="zNegativo w-full h-8/12 flex justify-center items-center">
        <div
          class="
            w-11/12
            h-full
            rounded-lg
            border-4
            bg-ffffff38
            border-white
            flex
            justify-center
            items-center
          "
        >
          <div
            class="
              w-full
              max-w-301
              xl:max-w-450
              h-full
              max-h-480
              grid grid-cols-1
            "
          >
            <!-- el de abajo -->
            <div class="w-full h-6/12 flex flex-col justify-center items-center">
              <p
                class="
                  w-8/12
                  text-white text-shadow text-center text-xl
                  sm:text-base
                  md:text-xl
                  xl:text-4xl
                  transform
                  translate-y-1/4
                  sm:translate-y-16
                  xl:translate-y-0
                  traslate
                  2xl:text-6xl
                "
              >
                Ahora podemos jugar todos juntos gracias a tu habilidad mental.
                
              </p>
              <p
                class="
                  mt-3
                  w-8/12
                  text-white text-shadow text-center text-xl
                  sm:text-base
                  md:text-xl
                  xl:text-4xl
                  transform
                  translate-y-1/4
                  sm:translate-y-16
                  xl:translate-y-0
                  traslate
                  2xl:text-6xl
                "
              >
                
                 Ayuda a Ratty en los siguientes niveles
              </p>
            </div>
            <div class="w-full h-2/12 flex justify-around">
              <div class="w-3/12 h-full flex justify-center items-center">
                <button class="flex justify-center items-center">
                  <img
                    class="h-full"
                    @click="onRepeatWorld"
                    src="../../assets/btns/repeatleve.png"
                    alt=""
                  />
                </button>
              </div>
              <div class="w-3/12 h-full flex justify-center items-center">
                <button class="flex justify-center items-center">
                  <img
                    class="h-full"
                    @click="goToLeveSelector"
                    src="../../assets/btns/levelsbtn.png"
                    alt=""
                  />
                </button>
              </div>
              <div class="w-3/12 h-full flex justify-center items-center">
                <button
                  @click="nextWorld()"
                  class="flex justify-center items-center"
                >
                  <img
                    class="h-full"
                    src="../../assets/btns/nextleve.png"
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full h-2/12">
        <div class="w-full h-4/12"></div>
        <div class="w-full h-8/12 bg-FFB442"></div>
      </div>
    </div>
  </div>
  <!-- Animales -->
  <div class="fixed bottom-0 z-10 pb-14 w-full flex justify-center">
    <div class="h-full">
      <img
        class="
          hidden
          reduce
          sm:w-32
          md-44
          transform
          xl:translate-y-6 xl:w-60
          2xl:w-35
          h-full
        "
        :src="getLeveSelectedGameThree.caracterWin"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MenuHeaderComponentVue from "../../components/header/MenuHeaderComponent.vue";
import router from "../../router";
export default {
  components: {
    MenuHeaderComponentVue,
  },
  computed: {
    ...mapGetters(["getLeveSelectedGameThree", "getGameInfo"]),
  },
  methods: {
    ...mapActions(["onNextWorld", "onRepeatWorld"]),
    nextWorld() {
      this.onNextWorld();
    },
    goToLeveSelector() {
      router.push("/game-three-leve-selector");
    },
  },

  created() {
    // console.log(this.getLeveTwoSelected.scenes)
    if (!this.getLeveSelectedGameThree.caracterWin) {
      router.push("/");
    }
  },
};
</script>

<style>
.blur-background-win {
  background: radial-gradient(
    97.57% 210.75% at 0.9% 2.98%,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  backdrop-filter: blur(42px);
  border-radius: 15px;
}
.btn-style {
  background: linear-gradient(153.43deg, #ffb636 16.67%, #ff9420 100%);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}
.card-text-win {
  background-image: url("../../assets/fondos/card3.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.card-text-win-green {
  background-image: url("../../assets/fondos/card3green.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.bg-blur-15 {
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  filter: blur(4px);
}
.zNegativo {
  z-index: -10;
}
</style>
