<template>
 <div v-if="getLeveSelectedGameThree.backgroundImg">
    <MenuHeaderComponentVue :isAnyOther="true" />

  <div class="fixed inset-0 w-screen h-screen">
    <div
      class="w-full h-full bg-no-repeat bg-cover"
      :style="{
        'background-image':
          'url(' + getLeveSelectedGameThree.backgroundImg + ')',
      }"
    >
    
    </div>
    <div class="fixed inset-0 top-0 w-full h-full">
      <div class="w-full h-2/12"></div>
      <div class="w-full h-9/12 flex justify-center items-center">
        <div
          class="
            w-11/12
            h-full
            bg-game-three bg-blur
            flex
            justify-center
            items-center
          "
        >
          <!-- <div class='w-full max-w-301 h-full max-h-480 grid grid-cols-1 '>
            <div v-for="x in 4" :key="x" class=' w-full h-full  flex justify-center items-center'>
                <div class='grid grid-cols-3 w-full h-full'>
                    <div v-for="y in 3" :key="y" class='w-full h-full flex justify-center items-center '>                        
                       
                       <img  :class="getDataWorld.caracterInit.rotate?'rotate':''" v-if="positionCaracter(x,y,getDataWorld.caracterInit)" :src="getDataWorld.caracterInit.img" alt="">                       
                       <img  :class="getDataWorld.caracterEnd.rotate?'rotate':''" v-if="positionCaracter(x,y,getDataWorld.caracterEnd)" :src="getDataWorld.caracterEnd.img" alt="">                       
                      
                    </div>
                </div>
            </div>
        </div> -->
        </div>
      </div>
      <div class="w-full h-1/12"></div>
    </div>
    <div class="fixed top-0 h-full w-full">
      <!-- <div class="w-full h-1/12 flex justify-center items-center " > </div> -->
      <div
        class="
          w-full
          h-2/12
          transform
          translate-y-23x
          flex
          justify-center
          items-center
        "
      >
        <img
          class="h-full"
          src="../../assets/animals/ratttyDialog.png"
          alt=""
        />
        <div
          class="
            h-full
            w-2/4
            ratty-dialog
            text-center text-white text-xl
            sm:text-sm
            md:text-xl
            xl:text-4xl
            2xl:text-6xl
            flex
            justify-center
            text-shadow
            items-center
          "
        >
          Ayúdame a llegar con mis amigos
        </div>
      </div>
      <div class="w-full h-9/12 flex justify-center items-center">
        <div
          class="
            w-11/12
            h-full
            rounded-lg
            border-4 border-white
            flex
            justify-center
            items-center
          "
        >
          <div
            class="
              w-full
              min-w-280 min-h-448
              max-w-320
              h-full
              max-h-480
              grid grid-cols-1
            "
          >
            <div
              v-for="x in 8"
              :key="x"
              class="w-full h-full flex justify-center items-center"
            >
              <div class="grid grid-cols-5 w-full h-1/6">
                <div
                  v-for="y in 5"
                  :key="y"
                  class="flex justify-center items-center "
                >
                  <div class="w-14 md:w-16 h-14 md:h-16 border border-white">
                    <img
                      v-if="getDataWorld.positions[x - 1][y - 1].img != ''"
                      @click="
                        gireTo(
                          getDataWorld.positions[x - 1][y - 1],
                          x - 1,
                          y - 1
                        )
                      "
                      class="transform img-complete "
                      :src="getDataWorld.positions[x - 1][y - 1].img"
                      :class="
                        'rotate-' +
                        getDataWorld.positions[x - 1][y - 1].actualPosition
                      "
                      alt=""
                    />
                    
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full h-1/12 flex justify-start items-center">
        <div class="w-full h-full bg-FFB442"></div>
      </div>
    </div>
    <div class="fixed bottom-0 z-10" :class="getDataWorld.caracterEnd.position">
      <div class="h-full flex justify-center">
        <img
          class="h-full w-52 xl:w-72 2xl:w-96"
          v-if="getIsWorldWin"
          src="../../assets/btns/felicitacion.png"
          alt=""
        />
        <img
          class="h-full w-24 xl:w-48 2xl:w-96"
          :class="getDataWorld.caracterEnd.rotate ? 'rotate-img' : ''"
          :src="getDataWorld.caracterEnd.img"
          alt=""
        />
      </div>
    </div>
  </div>
 </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MenuHeaderComponentVue from "../../components/header/MenuHeaderComponent.vue";
import router from "../../router";
export default {
  components: {
    MenuHeaderComponentVue,
  },
  unmounted() {
    // this.setIsWorldWin();
  },
  computed: {
    ...mapGetters([
      "getLeveSelectedGameThree",
      "getDataWorld",
      "getIsWorldWin",
      "getMessage",
    ]),
  },
  methods: {
    // Esto seria el equivalente a la inyeccion de un servicio en angular
    ...mapActions(["onRotateTuberia"]),
    gireTo(object, row, column) {
      if (!object.notRotate) {
        this.onRotateTuberia({ row, column });
      }
    },
    onBack() {
      router.push("/leve-selector");
    },
    positionCaracter(x, y, caracter) {
      if (caracter) {
        return x - 1 === caracter.row && y - 1 === caracter.colum;
      }
      return false;
    },
  },
  created() {
  // console.log(this.getLeveTwoSelected.scenes)
    if(!this.getLeveSelectedGameThree.backgroundImg){
      router.push("/")
    }
  },
};
</script>

<style>
.img-complete {
  object-fit: cover;
  object-position: center;
}
.blur-title {
  background: radial-gradient(
    97.57% 210.75% at 0.9% 2.98%,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  backdrop-filter: blur(42px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 15px;
}
.bg-game-three {
  background: radial-gradient(
    97.57% 210.75% at 0.9% 2.98%,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  backdrop-filter: blur(1px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 15px;
}
.bg-blur {
  backdrop-filter: blur(39px);
  -webkit-filter: blur(39px);
  -moz-filter: blur(39px);
  -o-filter: blur(39px);
  -ms-filter: blur(39px);
  filter: blur(39px);
}
.rotate-img {
  transform: scaleX(-1);
}
.ratty-dialog {
  background-image: url("../../assets/btns/btn-generic-not-whitespaces.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.reduceGame{
  width: 30px !important;
  height: 30px !important;
}
</style>