<template>
  <div class="w-auto h-screen bg-gradient-to-b from-0ab2ff to-white">
    <div class="h-2/12 flex justify-evenly items-center">
      <!-- Img -->
      <div class="w-2/4">
        <img class="w-2/5" src="../../assets/letras/mundozac.png" alt="" />
      </div>
      <!-- Botones -->
      <div class="w-1/4">
        <div class="flex items-center justify-between">
          <button
            @click="goCratorTips"
            class="
              py-2
              px-4
              bg-FECB00
              invisible
              text-white
              box-shadow
              text-shadow
              rounded-lg
            "
          >
            Nuevo Consejo
          </button>
          <p @click="logOut" class="cursor-pointer" for="">Cerrar sesión</p>
        </div>
      </div>
    </div>
    <div class="h-2/12 flex items-center">
      <div class="w-1/6"></div>
      <div class="w-2/6 gap-4">
        <div class="flex items-center">
          <img class="mr-4" src="../../assets/iconos/hoja.svg" alt="" />
          <p class="text-white text-base text-shadow">Consejos</p>
        </div>
        <button
          @click="goCratorTips"
          class="
            py-2
            mt-4
            px-4
            bg-FECB00
            text-shadow
            box-shadow
            text-white
            rounded-lg
          "
        >
          Nuevo Consejo
        </button>
      </div>
      <div class="w-3/6"></div>
    </div>
    <!-- <div class="pt-8">
         <div v-for="card,x in getCardsAme" :key="x">
             <div :class="x%2===0?'bg-gray-100':'bg-gray-300'" class="py-4 px-4">
                 <p>{{card._id}}</p>
                 <p>{{card.title}}</p>
                 <p>{{card.text}}</p>
                 <button @click='deleteCard(card._id)' class="py-2 px-4 bg-red-300 border-2 rounded-lg">Eliminar</button>
             </div>
         </div>
     </div> -->
    <div class="pt-8 flex justify-center">
      <table class="w-4/6">
        <thead>
          <tr class="flex justify-around items-center h-20">
            <!-- <th class=""><input type="checkbox"/></th> -->
            <!-- <th>id</th> -->
            <th class="w-1/4">Titulo</th>
            <th class="w-1/2">Texto</th>
            <th class="">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr
            :class="x % 2 === 0 ? 'bg-fdfdfd46' : 'bg-transparent'"
            class="flex justify-around h-28"
            v-for="(card, x) in getCardsAme"
            :key="x"
          >
            <td class="w-1/4 flex justify-center items-center">
              <input
                class="rounded-md border1px border-black"
                type="text"
                v-model="card.title"
              />
            </td>
            <td class="w-1/2 flex justify-center items-center overflow-y-auto">
              <textarea
                class="rounded-md border1px border-black w-full h-5/6"
                v-model="card.text"
              ></textarea>
            </td>
            <td class="flex justify-center items-center">
              <div class="flex justify-between">
                <img
                  @click="updateCard(card.title, card.text, card._id)"
                  class="object-contain cursor-pointer w-1/4 h-full"
                  src="../../assets/iconos/update.svg"
                  alt=""
                />
                <img
                  class="object-contain cursor-pointer w-1/4 h-full invisible"
                  src="../../assets/iconos/get.svg"
                  alt=""
                />
                <img
                  @click="deleteCard(card._id)"
                  class="object-contain w-1/4 cursor-pointer h-full"
                  src="../../assets/iconos/delete.svg"
                  alt=""
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import router from "../../router";

export default {
  data() {
    return {
      title: "",
      text: "",
    };
  },
  methods: {
    ...mapActions([
      "onGetCardsAmeApi",
      "onCreateCard",
      "onDeleteCard",
      "onUpdateCard",
    ]),
    deleteCard(id) {
      window.confirm("Deseas eliminarlo permanetemente?")
        ? this.onDeleteCard(id)
        : true;
    },
    // Actualizar titulo y texto
    updateCard(title, text, id) {
      this.onUpdateCard({title, text, id});
      window.alert("Consejo actualizado")
      // console.log(`actualizar ${title} y ${text}, ${id}`)
    },

    addCard() {
      this.title.trim();
      this.text.trim();
      if (this.title.length > 0 && this.text.length > 0) {
        this.onCreateCard({ title: this.title, text: this.text });
        (this.title = ""), (this.text = "");
      } else alert("falta un dato");
    },

    goCratorTips() {
      router.replace("/cms-creator-tips");
    },
    logOut() {
      router.replace("/");
    },
  },
  computed: {
    ...mapGetters(["getCardsAme"]),
  },
  created() {
    this.onGetCardsAmeApi();
  },
};
</script>

<style>
</style>