
export default {
  state: {
      leveSelected:{},
      optionSelected:{}
  },
  mutations: {
      setLeveSelected(state,leve){
        state.leveSelected = leve
      },
      setOptionSelected(state,option){
        state.optionSelected =option
      }
  },
  actions: {
      onSelectedLeveGameOne({commit},leve){
          commit('setLeveSelected',leve)
      },
      onSelectOption({commit,getters},{option={},textChange=''}){
        commit('setOptionSelected',option)
        commit('setLeveSelected',{...getters.getLeveSelected,zacDialog:textChange})
      },
  },
  getters:{
      getLeveSelected : state => state.leveSelected,
      getOptionSelected : state => state.optionSelected
  },
}
