export const gamesDataMenus = {
  gameOne: {
    title: "Lenguaje con Zac",
    availableForAge: "0-2",
    cardImgAnimal: "./img/background/circuloTemporal.png",
    description:
      'Durante este juego necesitarás hacer "Tap" diferentes botones en la pantalla',
    img: "./img/instructions/Zacinst.png",
    instructionConfirm: "./img/instructions/posterZac.png",
    selectorLeveImg: "./img/instructions/Zacinst.png",
    game: "/game-lenguaje",
    levels: {
      leve1: {
        id: 1,
        title: "Cuerpo",
        img: "./img/games/one/levels/CelularCuerpoMiniatura.png",
        zacDialog: "Aprendamos más sobre nuestras partes del cuerpo",
        titleGameInside: "Partes del cuerpo",
        options: {
          ojos: {
            title: "Ojos",
            miniature: "./img/games/one/leveone/Celular_Ojos.png",
            img: "./img/games/one/leveone/Celular_Ojos.png",
            audio: "./audios/gameOne/OJOS.mp3",
          },
          pies: {
            title: "Pies",
            miniature: "./img/games/one/leveone/Celular_Pies.png",
            img: "./img/games/one/leveone/Celular_Pies.png",
            audio: "./audios/gameOne/PIES.mp3",
          },
          manos: {
            title: "Manos",
            miniature: "./img/games/one/leveone/Celular_Manos.png",
            img: "./img/games/one/leveone/Celular_Manos.png",
            audio: "./audios/gameOne/MANOS.mp3",
          },
          nariz: {
            title: "Nariz",
            miniature: "./img/games/one/leveone/Celular_Nariz.png",
            img: "./img/games/one/leveone/Celular_Nariz.png",
            audio: "./audios/gameOne/NARIZ.mp3",
          },
          boca: {
            title: "Boca",
            miniature: "./img/games/one/leveone/Celular_Boca.png",
            img: "./img/games/one/leveone/Celular_Boca.png",
            audio: "./audios/gameOne/BOCA.mp3",
          },
          orejas: {
            title: "Orejas",
            miniature: "./img/games/one/leveone/Celular_Oreja.png",
            img: "./img/games/one/leveone/Celular_Oreja.png",
            audio: "./audios/gameOne/OREJAS.mp3",
          },
        },
      },
      leve2: {
        id: 2,
        title: "Familia",
        img: "./img/games/one/levels/Celular_PantallaInicio_Familia1.png",
        zacDialog: "Aprendamos más sobre nuestra familia",
        titleGameInside: "Familia",
        options: {
          mama: {
            title: "Mamá",
            miniature: "./img/games/one/leveTwo/higthScale/MamaZacCelular.png",
            img: "./img/games/one/leveTwo/higthScale/MamaZacCelular.png",
            audio: "./audios/gameTwo/MAMA.mp3",
          },
          papa: {
            title: "Papá",
            miniature: "./img/games/one/leveTwo/higthScale/PapaZacCelular.png",
            img: "./img/games/one/leveTwo/higthScale/PapaZacCelular.png",
            audio: "./audios/gameTwo/PAPA.mp3",
          },
          hermano: {
            title: "Hermano",
            miniature:
              "./img/games/one/leveTwo/higthScale/HermanoZacCelular.png",
            img: "./img/games/one/leveTwo/higthScale/HermanoZacCelular.png",
            audio: "./audios/gameTwo/HERMANO.mp3",
          },
          Hermana: {
            title: "Hermana",
            miniature:
              "./img/games/one/leveTwo/higthScale/HermanaZacCelular.png",
            img: "./img/games/one/leveTwo/higthScale/HermanaZacCelular.png",
            audio: "./audios/gameTwo/HERMANA.mp3",
          },
          Abuelo: {
            title: "Abuelo",
            miniature:
              "./img/games/one/leveTwo/higthScale/AbueloZacCelular.png",
            img: "./img/games/one/leveTwo/higthScale/AbueloZacCelular.png",
            audio: "./audios/gameTwo/ABUELO.mp3",
          },
          abuela: {
            title: "Abuela",
            miniature:
              "./img/games/one/leveTwo/higthScale/AbuelaZacCelular.png",
            img: "./img/games/one/leveTwo/higthScale/AbuelaZacCelular.png",
            audio: "./audios/gameTwo/ABUELA.mp3",
          },
        },
      },
      leve3: {
        id: 3,
        title: "Animales",
        img: "./img/games/one/levels/Celular_PantallaInicio_Animales1.png",
        zacDialog: "Aprendamos más sobre los animales",
        titleGameInside: "Animales",
        options: {
          cebra: {
            title: "Cebra",
            miniature: "./img/games/one/leveThree/higthScale/ZacCelular.png",
            img: "./img/games/one/leveThree/higthScale/ZacCelular.png",
            audio: "./audios/gameThree/CEBRA.mp3",
          },
          canguro: {
            title: "Canguro",
            miniature:
              "./img/games/one/leveThree/higthScale/CanguroCelular.png",
            img: "./img/games/one/leveThree/higthScale/CanguroCelular.png",
            audio: "./audios/gameThree/CANGURO.mp3",
          },
          panda: {
            title: "Panda",
            miniature: "./img/games/one/leveThree/higthScale/PandaCelular.png",
            img: "./img/games/one/leveThree/higthScale/PandaCelular.png",
            audio: "./audios/gameThree/PANDA.mp3",
          },
          jirafa: {
            title: "Jirafa",
            miniature: "./img/games/one/leveThree/higthScale/jirafaCelular.png",
            img: "./img/games/one/leveThree/higthScale/jirafaCelular.png",
            audio: "./audios/gameThree/JIRAFA.mp3",
          },
          elefante: {
            title: "Elefante",
            miniature:
              "./img/games/one/leveThree/higthScale/ElefanteCelular.png",
            img: "./img/games/one/leveThree/higthScale/ElefanteCelular.png",
            audio: "./audios/gameThree/ELEFANTE.mp3",
          },
          suricata: {
            title: "Suricata",
            miniature:
              "./img/games/one/leveThree/higthScale/SuricataCelular.png",
            img: "./img/games/one/leveThree/higthScale/SuricataCelular.png",
            audio: "./audios/gameThree/SURICATA.mp3",
          },
          avestruz: {
            title: "Avestruz",
            miniature:
              "./img/games/one/leveThree/higthScale/AvestruzCelular.png",
            img: "./img/games/one/leveThree/higthScale/AvestruzCelular.png",
            audio: "./audios/gameThree/AVESTRUZ.mp3",
          },
          buho: {
            title: "Búho",
            miniature: "./img/games/one/leveThree/higthScale/BuhoCelular.png",
            img: "./img/games/one/leveThree/higthScale/BuhoCelular.png",
            audio: "./audios/gameThree/BUHO.mp3",
          },
        },
      },
    },
  },
  gameTwo: {
    title: "¿Dónde está ZAC?",
    availableForAge: "3-6",
    cardImgAnimal: "./img/background/canguro.png",
    description:
    'Durante este juego necesitarás hacer "Tap" diferentes botones en la pantalla',
    img: "./img/instructions/CangInst.png",
    instructionConfirm: "./img/instructions/posterCanguro.png",
    selectorLeveImg: "./img/instructions/Zacinst.png",
    game: "/game-two-entry",
    levels: [
      {
        id: 1,
        title: "Parque de diversiones",
        textEntry: "¡Bienvenido al parque de diversiones! ",
        img: "./img/games/two/levels/CelularParque.png",
        background: "./img/games/two/background/parque.png",
        scenes: [
          {
            imgBackground: "./img/games/two/background/izquierd.png",
            objectsInScene: [
              {
                imgObject: "./img/games/two/objects/palomita.png",
                titleObject: "palomitas",
                numberOfObjects: 2,
                numberObjectsFinded: 0,
                positions: [
                  { position: [59, 69], isFinded: false,boxFinded:69 },
                  { position: [53, 63, 64, 54], isFinded: false,boxFinded:64 },
                ],
              },
              {
                imgObject: "./img/games/two/objects/chango.png",
                titleObject: "chango",
                numberOfObjects: 1,
                numberObjectsFinded: 0,
                positions: [{ position: [50, 60], isFinded: false ,boxFinded:60}],
              },
              {
                imgObject: "./img/games/two/objects/algodon.png",
                titleObject: "algodondeazucar",
                numberOfObjects: 1,
                numberObjectsFinded: 0,
                positions: [{ position: [48, 58,], isFinded: false,boxFinded:48 }],
              },
              {
                imgObject: "./img/games/two/objects/zac.png",
                titleObject: "zac",
                numberOfObjects: 1,
                numberObjectsFinded: 0,
                positions: [
                  { position: [114, 115, 124, 125, 134, 135], isFinded: false,boxFinded:125 },
                ],
              },
              {
                imgObject: "./img/games/two/objects/mamazac.png",
                titleObject: "mamazac",
                numberOfObjects: 1,
                numberObjectsFinded: 0,
                positions: [
                  { position: [46, 47, 56, 57, 66, 67], isFinded: false,boxFinded:57 },
                ],
              },
              {
                imgObject: "./img/games/two/objects/agua.png",
                titleObject: "agua",
                numberOfObjects: 1,
                numberObjectsFinded: 0,
                positions: [
                  { position: [160, 159, 169, 170], isFinded: false,boxFinded:159 },
                ],
              },
            ],
          },
          //
          {
            imgBackground: "./img/games/two/background/centro.png",
            objectsInScene: [
              {
                imgObject: "./img/games/two/objects/panda.png",
                titleObject: "panda",
                numberOfObjects: 1,
                numberObjectsFinded: 0,
                positions: [
                  { position: [93, 94, 95, 103, 104, 105], isFinded: false, boxFinded:104 },
                ],
              },
              {
                imgObject: "./img/games/two/objects/palomita.png",
                titleObject: "palomitas",
                numberOfObjects: 1,
                numberObjectsFinded: 0,
                positions: [{ position: [84], isFinded: false, boxFinded:84 }],
              },
              {
                imgObject: "./img/games/two/objects/chango.png",
                titleObject: "chango",
                numberOfObjects: 2,
                numberObjectsFinded: 0,
                positions: [
                  { position: [29, 39], isFinded: false, boxFinded:39 },
                  { position: [164, 174], isFinded: false, boxFinded:164 },
                ],
              },
              {
                imgObject: "./img/games/two/objects/algodon.png",
                titleObject: "algodon",
                numberOfObjects: 1,
                numberObjectsFinded: 0,
                positions: [{ position: [133,134], isFinded: false, boxFinded:134 }],
              },
              {
                imgObject: "./img/games/two/objects/agua.png",
                titleObject: "agua",
                numberOfObjects: 1,
                numberObjectsFinded: 0,
                positions: [{ position: [185, 195], isFinded: false, boxFinded:195 }],
              },
              {
                imgObject: "./img/games/two/objects/papazac.png",
                titleObject: "agua",
                numberOfObjects: 1,
                numberObjectsFinded: 0,
                positions: [
                  {
                    position: [149, 150, 159, 160, 169, 170, 180],
                    isFinded: false, boxFinded:159
                  },
                ],
              },
            ],
          },
          //
          {
            imgBackground: "./img/games/two/background/derech.png",
            objectsInScene: [
              {
                imgObject: "./img/games/two/objects/canguro.png",
                titleObject: "canguro",
                numberOfObjects: 1,
                numberObjectsFinded: 0,
                positions: [
                  { position: [145, 155, 156, 165, 166], isFinded: false, boxFinded:155 },
                ],
              },
              {
                imgObject: "./img/games/two/objects/palomita.png",
                titleObject: "palomitas",
                numberOfObjects: 2,
                numberObjectsFinded: 0,
                positions: [
                  { position: [161, 171,162,172], isFinded: false, boxFinded:162 },
                  { position: [136,137], isFinded: false, boxFinded:137 },
                ],
              },
              {
                imgObject: "./img/games/two/objects/chango.png",
                titleObject: "chango",
                numberOfObjects: 1,
                numberObjectsFinded: 0,
                positions: [{ position: [118, 119], isFinded: false, boxFinded:118 }],
              },
              {
                imgObject: "./img/games/two/objects/algodon.png",
                titleObject: "algodon",
                numberOfObjects: 2,
                numberObjectsFinded: 0,
                positions: [
                  { position: [33], isFinded: false , boxFinded:33},
                  { position: [131, 132], isFinded: false, boxFinded:132 },
                ],
              },
              {
                imgObject: "./img/games/two/objects/agua.png",
                titleObject: "agua",
                numberOfObjects: 1,
                numberObjectsFinded: 0,
                positions: [{ position: [153, 154], isFinded: false, boxFinded:154 }],
              },
            ],
          },
        ],
      },
      {
        id: 2,
        title: "Salón de clases",
        textEntry: "¡Bienvenido al salon de clases!",
        img: "./img/games/two/levels/CelularEscuela.png",
        background: "./img/games/two/background/escuela.png",
        scenes: [
          {
            imgBackground: "./img/games/two/background/leve2l.png",
            objectsInScene: [
              {
                imgObject: "./img/games/two/objects/libros.png",
                titleObject: "libros",
                numberOfObjects: 1,
                numberObjectsFinded: 0,
                positions: [{ position: [125, 126, 136], isFinded: false, boxFinded:136 }],
              },
              {
                imgObject: "./img/games/two/objects/mochila.png",
                titleObject: "mochila",
                numberOfObjects: 1,
                numberObjectsFinded: 0,
                positions: [
                  {
                    position: [151, 152, 161, 162, 163, 171, 172, 173],
                    isFinded: false, boxFinded:163
                  },
                ],
              },
              {
                imgObject: "./img/games/two/objects/abaco.png",
                titleObject: "abaco",
                numberOfObjects: 2,
                numberObjectsFinded: 0,
                positions: [
                  { position: [19, 20, 29, 30, 40], isFinded: false, boxFinded:29 },
                  { position: [106, 107, 116, 117, 127], isFinded: false, boxFinded:117 },
                ],
              },
              {
                imgObject: "./img/games/two/objects/reloj.png",
                titleObject: "reloj",
                numberOfObjects: 1,
                numberObjectsFinded: 0,
                positions: [
                  { position: [14, 15, 24, 25, 34, 35], isFinded: false, boxFinded:25 },
                ],
              },
              {
                imgObject: "./img/games/two/objects/panda.png",
                titleObject: "panda",
                numberOfObjects: 1,
                numberObjectsFinded: 0,
                positions: [
                  {
                    position: [
                      143, 144, 145, 146, 147, 148, 149, 154, 155, 156, 157,
                      158, 159, 164, 165, 166, 167, 168, 177, 178, 179, 188,
                      189,
                    ],
                    isFinded: false, boxFinded:156
                  },
                ],
              },
            ],
          },
          //
          {
            imgBackground: "./img/games/two/background/leve2c.png",
            objectsInScene: [
              {
                imgObject: "./img/games/two/objects/libros.png",
                titleObject: "libros",
                numberOfObjects: 3,
                numberObjectsFinded: 0,
                positions: [
                  { position: [1, 2, 3, 11, 12, 13, 22, 23], isFinded: false,boxFinded:13 },
                  { position: [24, 25, 34, 35, 44, 45], isFinded: false,boxFinded:35 },
                  { position: [148, 149, 158, 159], isFinded: false,boxFinded:148 },
                ],
              },
              {
                imgObject: "./img/games/two/objects/mochila.png",
                titleObject: "mochila",
                numberOfObjects: 1,
                numberObjectsFinded: 0,
                positions: [
                  {
                    position: [91, 92, 93, 101, 102, 103, 111, 112, 113],
                    isFinded: false,boxFinded:103
                  },
                ],
              },
              {
                imgObject: "./img/games/two/objects/tamgram.png",
                titleObject: "tamgram",
                numberOfObjects: 1,
                numberObjectsFinded: 0,
                positions: [{ position: [59, 60, 69, 70], isFinded: false,boxFinded:69 }],
              },
              {
                imgObject: "./img/games/two/objects/cuaderno.png",
                titleObject: "cuaderno",
                numberOfObjects: 3,
                numberObjectsFinded: 0,
                positions: [
                  { position: [15, 16, 25, 26], isFinded: false,boxFinded:26 },
                  { position: [73, 74, 83, 84], isFinded: false,boxFinded:74 },
                  { position: [154, 155, 164, 165, 174, 175], isFinded: false,boxFinded:165 },
                ],
              },
              {
                imgObject: "./img/games/two/objects/canguro.png",
                titleObject: "canguro",
                numberOfObjects: 1,
                numberObjectsFinded: 0,
                positions: [
                  {
                    position: [
                      27, 37, 38, 46, 47, 56, 57, 66, 67, 75, 76, 77, 78, 85,
                      86, 87, 88, 89, 97,
                    ],
                    isFinded: false,boxFinded:37
                  },
                ],
              },
            ],
          },
          //
          {
            imgBackground: "./img/games/two/background/leve2r.png",
            objectsInScene: [
              {
                imgObject: "./img/games/two/objects/libros.png",
                titleObject: "libros",
                numberOfObjects: 2,
                numberObjectsFinded: 0,
                positions: [
                  { position: [41, 42, 51, 52], isFinded: false,boxFinded:52 },
                  { position: [122, 123, 132, 133], isFinded: false,boxFinded:133 },
                ],
              },
              {
                imgObject: "./img/games/two/objects/mochila.png",
                titleObject: "mochilas",
                numberOfObjects: 2,
                numberObjectsFinded: 0,
                positions: [
                  { position: [74, 83, 84, 93, 94, 103, 104], isFinded: false,boxFinded:94 },
                  {
                    position: [156, 165, 166, 175, 176, 185, 186],
                    isFinded: false, boxFinded:176
                  },
                ],
              },
              {
                imgObject: "./img/games/two/objects/abaco.png",
                titleObject: "abaco",
                numberOfObjects: 1,
                numberObjectsFinded: 0,
                positions: [
                  { position: [23, 24, 33, 34, 43, 44], isFinded: false,boxFinded:34 },
                ],
              },
              {
                imgObject: "./img/games/two/objects/cuaderno.png",
                titleObject: "cuaderno",
                numberOfObjects: 2,
                numberObjectsFinded: 0,
                positions: [
                  { position: [82, 92], isFinded: false,boxFinded:82 },
                  { position: [114, 115, 125, 124], isFinded: false,boxFinded:125 },
                ],
              },
              {
                imgObject: "./img/games/two/objects/zac.png",
                titleObject: "zac",
                numberOfObjects: 1,
                numberObjectsFinded: 0,
                positions: [
                  {
                    position: [
                      86, 87, 95, 96, 97, 105, 106, 107, 116, 117, 126, 127,
                      135, 136, 137, 144, 145, 146, 147, 154, 155, 157,
                    ],
                    isFinded: false,boxFinded:96
                  },
                ],
              },
              {
                imgObject: "./img/games/two/objects/buho.png",
                titleObject: "buho",
                numberOfObjects: 1,
                numberObjectsFinded: 0,
                positions: [
                  {
                    position: [
                      15, 18, 19, 25, 26, 27, 28, 29, 35, 36, 37, 38, 39, 45,
                      46, 47, 48, 49, 56, 57, 58, 67, 68, 69,
                    ],
                    isFinded: false,boxFinded:47
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 3,
        title: "Playa",
        textEntry: "¡Bienvenido a la playa!",
        img: "./img/games/two/levels/CelularPlaya.png",
        background: "./img/games/two/background/playa.png",
        scenes: [
          {
            imgBackground: "./img/games/two/background/levetl.png",
            objectsInScene: [
              {
                imgObject: "./img/games/two/objects/papazac.png",
                titleObject: "papazac",
                numberOfObjects: 1,
                numberObjectsFinded: 0,
                positions: [
                  {
                    position: [27, 36, 37, 38, 46, 47, 48, 57, 58, 67, 77],
                    isFinded: false,boxFinded:37
                  },
                ],
              },
              {
                imgObject: "./img/games/two/objects/mamazac.png",
                titleObject: "mamazac",
                numberOfObjects: 1,
                numberObjectsFinded: 0,
                positions: [
                  {
                    position: [
                      81, 82, 91, 92, 101, 102, 111,113, 112, 121, 122, 131, 132,
                    ],
                    isFinded: false,boxFinded:102
                  },
                ],
              },
              {
                imgObject: "./img/games/two/objects/bloqueador.png",
                titleObject: "bloqueador",
                numberOfObjects: 2,
                numberObjectsFinded: 0,
                positions: [
                  { position: [45, 55], isFinded: false,boxFinded:45 },
                  { position: [126], isFinded: false,boxFinded:126 },
                ],
              },
              {
                imgObject: "./img/games/two/objects/chango.png",
                titleObject: "chango",
                numberOfObjects: 2,
                numberObjectsFinded: 0,
                positions: [
                  { position: [32, 33, 42, 43], isFinded: false,boxFinded:43 },
                  { position: [69, 70, 79, 80], isFinded: false,boxFinded:79 },
                ],
              },
              {
                imgObject: "./img/games/two/objects/pelota.png",
                titleObject: "pelota",
                numberOfObjects: 2,
                numberObjectsFinded: 0,
                positions: [
                  { position: [54, 64], isFinded: false,boxFinded:64 },
                  { position: [123, 114, 124], isFinded: false,boxFinded:114 },
                ],
              },
              {
                imgObject: "./img/games/two/objects/coco.png",
                titleObject: "coco",
                numberOfObjects: 2,
                numberObjectsFinded: 0,
                positions: [{ position: [108, 118], isFinded: false, boxFinded: 118 },
                { position: [63], isFinded: false,boxFinded:63 }],
              },
            ],
          },
          //
          {
            imgBackground: "./img/games/two/background/levetc.png",
            objectsInScene: [
              {
                imgObject: "./img/games/two/objects/hermanozac.png",
                titleObject: "hermanozac",
                numberOfObjects: 1,
                numberObjectsFinded: 0,
                positions: [
                  { position: [28, 29, 38, 39, 48, 49], isFinded: false,boxFinded:39 },
                ],
              },
              {
                imgObject: "./img/games/two/objects/zac.png",
                titleObject: "zac",
                numberOfObjects: 1,
                numberObjectsFinded: 0,
                positions: [
                  {
                    position: [
                      118, 119, 128, 129, 138, 139, 148, 149, 150, 158, 159,
                      160,
                    ],
                    isFinded: false,boxFinded:139
                  },
                ],
              },
              {
                imgObject: "./img/games/two/objects/bloqueador.png",
                titleObject: "bloqueador",
                numberOfObjects: 2,
                numberObjectsFinded: 0,
                positions: [
                  { position: [143, 153,144,154], isFinded: false,boxFinded:154 },
                  { position: [171,181,172,182], isFinded: false,boxFinded:182 },
                ],
              },
              {
                imgObject: "./img/games/two/objects/lentes.png",
                titleObject: "lentes",
                numberOfObjects: 2,
                numberObjectsFinded: 0,
                positions: [{ position: [105, 106], isFinded: false,boxFinded:106 },{ position: [166, 167,177], isFinded: false,boxFinded:167 }],
              },
              {
                imgObject: "./img/games/two/objects/peces.png",
                titleObject: "peces",
                numberOfObjects: 1,
                numberObjectsFinded: 0,
                positions: [{ position: [174,175,184,185], isFinded: false,boxFinded:175 }],
              },
              {
                imgObject: "./img/games/two/objects/pelota.png",
                titleObject: "pelota",
                numberOfObjects: 1,
                numberObjectsFinded: 0,
                positions: [
                  { position: [136, 137, 146, 147], isFinded: false ,boxFinded:147},
                ],
              },
            ],
          },
          //
          {
            imgBackground: "./img/games/two/background/levetr.png",
            objectsInScene: [
              {
                imgObject: "./img/games/two/objects/jirafaCelular.png",
                titleObject: "jirafa",
                numberOfObjects: 1,
                numberObjectsFinded: 0,
                positions: [
                  {
                    position: [
                      44, 45, 54, 55, 64, 65, 74, 75, 84, 85, 86, 94, 95, 105,
                    ],
                    isFinded: false,boxFinded:75
                  },
                ],
              },
              {
                imgObject: "./img/games/two/objects/tortuga.png",
                titleObject: "tortuga",
                numberOfObjects: 1,
                numberObjectsFinded: 0,
                positions: [
                  { position: [165, 174, 175, 176, 184, 185], isFinded: false,boxFinded:185 },
                ],
              },
              {
                imgObject: "./img/games/two/objects/bloqueador.png",
                titleObject: "bloqueador",
                numberOfObjects: 2,
                numberObjectsFinded: 0,
                positions: [
                  { position: [145], isFinded: false,boxFinded:145 },
                  { position: [129], isFinded: false,boxFinded:129 },
                ],
              },
              {
                imgObject: "./img/games/two/objects/lentes.png",
                titleObject: "lentes",
                numberOfObjects: 2,
                numberObjectsFinded: 0,
                positions: [
                  { position: [23, 24, 25], isFinded: false,boxFinded:24 },
                  { position: [147, 148], isFinded: false,boxFinded:147 },
                ],
              },
              {
                imgObject: "./img/games/two/objects/peces.png",
                titleObject: "peces",
                numberOfObjects: 1,
                numberObjectsFinded: 0,
                positions: [{ position: [48, 49], isFinded: false,boxFinded:48 }],
              },
              {
                imgObject: "./img/games/two/objects/chango.png",
                titleObject: "chango",
                numberOfObjects: 1,
                numberObjectsFinded: 0,
                positions: [
                  { position: [91, 92, 93, 101, 102, 103], isFinded: false,boxFinded:93 },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  gameThree: {
    title: "Escape de Tuberías",
    availableForAge: "7-10",
    cardImgAnimal: "./img/background/raton.png",
    description:
       'Durante este juego necesitarás hacer "Tap" diferentes botones en la pantalla',
    img: "./img/instructions/Ceular_RattyHablando.png",
    instructionConfirm: "./img/instructions/posterRatty.png",
    selectorLeveImg: "./img/instructions/Ceular_RattyHablando.png",
    game: "/game-three-leve-selector",
    levels: {
      leve1: {
        id: 1,
        title: "Casa de Campo",
        backgroundImg: "./img/games/three/background/newbg1.png",
        caracterWin: "./img/caracters/RattyJirafaSuricata.png",
        textWinOne: "¡Muchas gracias!",
        imgSceneSlector: "./img/games/three/levels/casacampo.png",
        textWinTwo:
          "Ahora podemos jugar todos juntos gracias a tu habilidad mental.  ",
        img: "./img/games/three/levels/CelularCampoMiniatura.png",
        numerWorlds: 4,
        scenes: [
          {
            caracterInit: {
              img: "./img/caracters/RattyBCelular.png",
              colum: 2,
              row: 0,
              rotate: false,
            },
            caracterEnd: {
              img: "./img/caracters/RattyJirafaSuricata.png",
              colum: 2,
              row: 3,
              position: "right-0",
              rotate: false,
            },
            positions: [
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [90],
                  actualPosition: 90,
                },
                {
                  img: "./img/caracters/RattyMeta.png",
                  winPosition: [0],
                  actualPosition: 0,
                  notRotate: true,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [90],
                  actualPosition: 180,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [270],
                  actualPosition: 0,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [0],
                  actualPosition: 270,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [180],
                  actualPosition: 0,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 0,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 0,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 0,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [0],
                  actualPosition: 0,
                },
                {
                  img: "./img/caracters/meta.png",
                  winPosition: [0],
                  actualPosition: 0,
                  notRotate: true,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
            ],
          },
          {
            caracterInit: {
              img: "./img/caracters/RattyBCelular.png",
              colum: 0,
              row: 0,
              rotate: true,
            },
            caracterEnd: {
              img: "./img/caracters/RattyJirafaSuricata.png",
              colum: 2,
              row: 3,
              position: "right-0",
              rotate: false,
            },
            positions: [
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/caracters/RattyMetaBottomRight.png",
                  winPosition: [0],
                  actualPosition: 0,
                  notRotate: true,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [0],
                  actualPosition: 0,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [180],
                  actualPosition: 270,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [0],
                  actualPosition: 270,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [180],
                  actualPosition: 180,
                },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 0,
                },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [90],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [270],
                  actualPosition: 270,
                },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 0,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [0],
                  actualPosition: 0,
                },
                {
                  img: "./img/caracters/meta.png",
                  winPosition: [0],
                  actualPosition: 0,
                  notRotate: true,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
            ],
          },
          {
            caracterInit: {
              img: "./img/caracters/RattyBCelular.png",
              colum: 1,
              row: 2,
              rotate: false,
            },
            caracterEnd: {
              img: "./img/caracters/RattyJirafaSuricata.png",
              colum: 0,
              row: 3,
              rotate: true,
              position: "",
            },
            positions: [
             
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [90],
                  actualPosition: 0,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [180],
                  actualPosition: 180,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 0,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 0,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [0],
                  actualPosition: 0,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CTX.png",
                  winPosition: [0, 90, 180, 270],
                  actualPosition: 0,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [180],
                  actualPosition: 270,
                },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/caracters/RattyMetaTop90.png",
                  winPosition: [0],
                  actualPosition: 0,
                  notRotate: true,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 90,
                },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 0,
                },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/caracters/meta.png",
                  winPosition: [0],
                  actualPosition: 0,
                  notRotate: true,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 0,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [270],
                  actualPosition: 270,
                },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
             
            ],
          },
          {
            caracterInit: {
              img: "./img/caracters/RattyBCelular.png",
              colum: 2,
              row: 0,
              rotate: false,
            },
            caracterEnd: {
              img: "./img/caracters/RattyJirafaSuricata.png",
              colum: 0,
              row: 3,
              rotate: true,
              position: "",
            },
            positions: [
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [90],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 90,
                },
                {
                  img: "./img/caracters/RattyMeta.png",
                  winPosition: [0],
                  actualPosition: 0,
                  notRotate: true,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 0,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [90],
                  actualPosition: 180,
                },
                {
                  img: "./img/games/three/tuberias/CTX.png",
                  winPosition: [0, 90, 180, 270],
                  actualPosition: 0,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [180],
                  actualPosition: 180,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [0],
                  actualPosition: 0,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [270],
                  actualPosition: 270,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 0,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 0,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/caracters/meta.png",
                  winPosition: [0],
                  actualPosition: 0,
                  notRotate: true,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [270],
                  actualPosition: 270,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              
            ],
          },
        ],
      },
      leve2: {
        id: 2,
        title: "Arcade",
        img: "./img/games/three/levels/CelularMiniaturaArcade.png",
        caracterWin: "./img/caracters/RattyZacyZiggy.png",
        backgroundImg:
          "./img/games/three/background/newbg2.png",
        textWinOne: "¡Increíble!",
        imgSceneSlector: "./img/games/three/levels/arcade.png",
        textWinTwo:
          "Gracias a tu intelecto, podemos jugar todos juntos en el Arcade.  ",
        numerWorlds: 4,
        scenes: [
          {
            caracterInit: {
              img: "./img/caracters/RattyBCelular.png",
              colum: 2,
              row: 0,
              rotate: false,
            },
            caracterEnd: {
              img: "./img/caracters/RattyZacyZiggy.png",
              colum: 0,
              row: 3,
              position: "",
              rotate: false,
            },
            positions: [
             
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [90],
                  actualPosition: 270,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 90,
                },
                {
                  img: "./img/caracters/RattyMeta.png",
                  winPosition: [0],
                  actualPosition: 0,
                  notRotate: true,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [0],
                  actualPosition: 180,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 0,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [180],
                  actualPosition: 90,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 0,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [90],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [270],
                  actualPosition: 0,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 0,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [0],
                  actualPosition: 0,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [180],
                  actualPosition: 90,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/caracters/meta.png",
                  winPosition: [0],
                  actualPosition: 0,
                  notRotate: true,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 0,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [270],
                  actualPosition: 0,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              
            ],
          },
          {
            caracterInit: {
              img: "./img/caracters/RattyBCelular.png",
              colum: 2,
              row: 0,
              rotate: false,
            },
            caracterEnd: {
              img: "./img/caracters/RattyZacyZiggy.png",
              colum: 2,
              row: 3,
              position: "right-0",
              rotate: true,
            },
            positions: [
              
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [90],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 90,
                },
                {
                  img: "./img/caracters/RattyMeta.png",
                  winPosition: [0],
                  actualPosition: 0,
                  notRotate: true,
                },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 0,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [90],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [180],
                  actualPosition: 180,
                },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 0,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 0,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 0,
                },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [0],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [270],
                  actualPosition: 90,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 0,
                },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [90],
                  actualPosition: 180,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [270],
                  actualPosition: 180,
                },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [0],
                  actualPosition: 270,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 0,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 0,
                },
                {
                  img: "./img/caracters/meta.png",
                  winPosition: [0],
                  actualPosition: 0,
                  notRotate: true,
                },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              
            ],
          },
          {
            caracterInit: {
              img: "./img/caracters/RattyBCelular.png",
              colum: 0,
              row: 0,
              rotate: true,
            },
            caracterEnd: {
              img: "./img/caracters/RattyZacyZiggy.png",
              colum: 2,
              row: 3,
              rotate: true,
              position: "right-0",
            },
            positions: [
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/caracters/RattyMetaBottomRight.png",
                  winPosition: [0],
                  actualPosition: 0,
                  notRotate: true,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [90],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [180],
                  actualPosition: 180,
                },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 0,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 0,
                },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [0],
                  actualPosition: 270,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [270],
                  actualPosition: 270,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 0,
                },
              ],
              [
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [90],
                  actualPosition: 180,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [180],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [90],
                  actualPosition: 180,
                },
                {
                  img: "./img/games/three/tuberias/CTX.png",
                  winPosition: [0, 90, 180, 270],
                  actualPosition: 0,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [270],
                  actualPosition: 0,
                },
              ],
              [
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [0],
                  actualPosition: 270,
                },
                {
                  img: "./img/games/three/tuberias/CTX.png",
                  winPosition: [0, 90, 180, 270],
                  actualPosition: 0,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [180],
                  actualPosition: 0,
                },
              ],
              [
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [0],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [180, 0],
                  actualPosition: 0,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 0,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [270],
                  actualPosition: 0,
                },
              ],
              [
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [0],
                  actualPosition: 270,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 90,
                },
                {
                  img: "./img/caracters/meta.png",
                  winPosition: [0],
                  actualPosition: 0,
                  notRotate: true,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
            ],
          },
          {
            caracterInit: {
              img: "./img/caracters/RattyBCelular.png",
              colum: 1,
              row: 2,
              rotate: false,
            },
            caracterEnd: {
              img: "./img/caracters/RattyZacyZiggy.png",
              colum: 2,
              row: 3,
              position: "right-0",
              rotate: true,
            },
            positions: [
              [
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [90],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [180],
                  actualPosition: 180,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 0,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 0,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [0],
                  actualPosition: 0,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [180],
                  actualPosition: 90,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 0,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [90],
                  actualPosition: 180,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [270],
                  actualPosition: 0,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 0,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [0],
                  actualPosition: 0,
                },
                {
                  img: "./img/caracters/RattyMeta.png",
                  winPosition: [0],
                  actualPosition: 0,
                  notRotate: true,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [0],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [180],
                  actualPosition: 180,
                },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [90],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [270],
                  actualPosition: 270,
                },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/caracters/meta.png",
                  winPosition: [0],
                  actualPosition: 0,
                  notRotate: true,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
            ],
          },
        ],
      },
      leve3: {
        id: 3,
        title: "Centro comercial",
        img: "./img/games/three/levels/Celular_Miniatura_Mall.png",
        caracterWin: "./img/caracters/RattyBuhoAvestruz.png",
        textWinOne: "¡Muchas gracias! ",
        imgSceneSlector: "./img/games/three/levels/mall.png",
        textWinTwo: "Estoy impresionado con tu inteligencia.",
        backgroundImg: "./img/games/three/background/newbg3.png",
        numerWorlds: 4,
        scenes: [
          {
            caracterInit: {
              img: "./img/caracters/RattyBCelular.png",
              colum: 2,
              row: 0,
              rotate: false,
            },
            caracterEnd: {
              img: "./img/caracters/RattyBuhoAvestruz.png",
              colum: 0,
              position: "",
              row: 3,
              rotate: false,
            },
            positions: [
              [
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [90],
                  actualPosition: 180,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [180],
                  actualPosition: 90,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/caracters/RattyMetaBottom.png",
                  winPosition: [0],
                  actualPosition: 0,
                  notRotate: true,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [0],
                  actualPosition: 180,
                },
                {
                  img: "./img/games/three/tuberias/CTX.png",
                  winPosition: [0, 90, 180, 270],
                  actualPosition: 0,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [270],
                  actualPosition: 270,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [0],
                  actualPosition: 270,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [180],
                  actualPosition: 90,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 90,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [90],
                  actualPosition: 180,
                },
                {
                  img: "./img/games/three/tuberias/CTX.png",
                  winPosition: [0, 90, 180, 270],
                  actualPosition: 0,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [180],
                  actualPosition: 180,
                },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [0],
                  actualPosition: 270,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [270],
                  actualPosition: 90,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 180],
                  actualPosition: 90,
                },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/caracters/meta.png",
                  winPosition: [0],
                  actualPosition: 0,
                  notRotate: true,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [270],
                  actualPosition: 270,
                },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
            ],
          },
          {
            caracterInit: {
              img: "./img/caracters/RattyBCelular.png",
              colum: 1,
              row: 2,
              rotate: true,
            },
            caracterEnd: {
              img: "./img/caracters/RattyBuhoAvestruz.png",
              colum: 2,
              row: 3,
              position: "right-0",
              rotate: true,
            },
            positions: [
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [90],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [180],
                  actualPosition: 90,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [90],
                  actualPosition: 180,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 0,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [270],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 90,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 0,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [0],
                  actualPosition: 270,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [180],
                  actualPosition: 90,
                },
              ],
              [
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 0,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/caracters/RattyMetaRight.png",
                  winPosition: [0],
                  actualPosition: 0,
                  notRotate: true,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [180],
                  actualPosition: 180,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 90,
                },
              ],
              [
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [0],
                  actualPosition: 0,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [180],
                  actualPosition: 90,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [0],
                  actualPosition: 270,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [270],
                  actualPosition: 0,
                },
              ],
              [
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [90],
                  actualPosition: 180,
                },
                {
                  img: "./img/games/three/tuberias/CTX.png",
                  winPosition: [0, 90, 180, 270],
                  actualPosition: 0,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [180],
                  actualPosition: 90,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [0],
                  actualPosition: 180,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [270],
                  actualPosition: 270,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [0],
                  actualPosition: 0,
                },
                {
                  img: "./img/caracters/meta.png",
                  winPosition: [0],
                  actualPosition: 0,
                  notRotate: true,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
               
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                
              ],
            ],
          },
          {
            caracterInit: {
              img: "./img/caracters/RattyBCelular.png",
              colum: 2,
              row: 0,
              rotate: false,
            },
            caracterEnd: {
              img: "./img/caracters/RattyBuhoAvestruz.png",
              colum: 2,
              position: "right-0",
              row: 3,
              rotate: true,
            },
            positions: [
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [90],
                  actualPosition: 0,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [180],
                  actualPosition: 270,
                },
                {
                  img: "./img/caracters/RattyMetaBottom.png",
                  winPosition: [0],
                  actualPosition: 0,
                  notRotate: true,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [90],
                  actualPosition: 180,
                },
                {
                  img: "./img/games/three/tuberias/CTX.png",
                  winPosition: [0, 90, 180, 270],
                  actualPosition: 0,
                },
                {
                  img: "./img/games/three/tuberias/CTX.png",
                  winPosition: [0, 90, 180, 270],
                  actualPosition: 0,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [270],
                  actualPosition: 0,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [0],
                  actualPosition: 270,
                },
                {
                  img: "./img/games/three/tuberias/CTX.png",
                  winPosition: [0, 90, 180, 270],
                  actualPosition: 0,
                },
                {
                  img: "./img/games/three/tuberias/CTX.png",
                  winPosition: [0, 90, 180, 270],
                  actualPosition: 0,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [180],
                  actualPosition: 90,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [90],
                  actualPosition: 180,
                },
                {
                  img: "./img/games/three/tuberias/CTX.png",
                  winPosition: [0, 90, 180, 270],
                  actualPosition: 0,
                },
                {
                  img: "./img/games/three/tuberias/CTX.png",
                  winPosition: [0, 90, 180, 270],
                  actualPosition: 0,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [270],
                  actualPosition: 0,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [0],
                  actualPosition: 270,
                },
                {
                  img: "./img/games/three/tuberias/CTX.png",
                  winPosition: [0, 90, 180, 270],
                  actualPosition: 0,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [270],
                  actualPosition: 0,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [0],
                  actualPosition: 270,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 90,
                },
                {
                  img: "./img/caracters/meta.png",
                  winPosition: [0],
                  actualPosition: 0,
                  notRotate: true,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
            ],
          },
          {
            caracterInit: {
              img: "./img/caracters/RattyBCelular.png",
              colum: 1,
              row: 2,
              rotate: false,
            },
            caracterEnd: {
              img: "./img/caracters/RattyBuhoAvestruz.png",
              colum: 2,
              row: 3,
              position: "right-0",
              rotate: true,
            },
            positions: [
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [90],
                  actualPosition: 180,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [180],
                  actualPosition: 90,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 90,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 0,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [90],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [270],
                  actualPosition: 270,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [90],
                  actualPosition: 180,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [270],
                  actualPosition: 0,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [0],
                  actualPosition: 0,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [180],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [0],
                  actualPosition: 270,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 0,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [180],
                  actualPosition: 90,
                },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [0],
                  actualPosition: 0,
                },
                {
                  img: "./img/caracters/RattyMeta.png",
                  winPosition: [0],
                  actualPosition: 0,
                  notRotate: true,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [90, 270],
                  actualPosition: 90,
                },
              ],
              [
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [90],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 0,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [270],
                  actualPosition: 270,
                },
              ],
              [
                {
                  img: "./img/games/three/tuberias/CT.png",
                  winPosition: [0],
                  actualPosition: 0,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 90,
                },
                {
                  img: "./img/games/three/tuberias/CTH.png",
                  winPosition: [0, 180],
                  actualPosition: 90,
                },
                {
                  img: "./img/caracters/meta.png",
                  winPosition: [0],
                  actualPosition: 0,
                  notRotate: true,
                },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
              [
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
                { img: "", winPosition: [0], actualPosition: 0 },
              ],
            ],
          },
        ],
      },
    },
  },
  gameNext: {
    isEnabled: false,
    title: "PRÓXIMAMENTE",
    availableForAge: "all",
    // cardImgAnimal:'./img/background/cebra.png',
    cardImgAnimal: "./img/background/circuloTemporal.png",
    description: "PRÓXIMAMENTE",
    img: "./img/icons/block.png",
    instructionConfirm: "./img/instructions/Zacinst.png",
    game: "",
  },
};
