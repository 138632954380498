<template>
  <div class="w-full h-full transform xl:translate-y-4 translateCircle">
    <!-- background__nubes -->
    <div class="absolute top-0 z-10 w-full h-full">
      <div class="w-full h-4/12 xl:h-2/12"></div>
      <div class="w-full h-8/12 xl:h-10/12 cloudmiddle"></div>
    </div>

    <div class="absolute w-full h-full">
      <!-- titulo y circulo-->
      <div class="xl909 w-full h-1/2 flex flex-col justify-end items-center titletranslate">
        <div
          :class="ageTitle.length > 16 ? 'pb-4 md:pb-8' : 'pb-4 md:pb-8'"
          class="w-full flex flex-col justify-center items-center"
        >
          <p
            :id="idCurve"
            class="
              curve
              z-30
              text-white text-xl
              sm:text-3xl
              md:text-3xl
              xl:text-5xl
              2xl:text-8xl
              md:tracking-wide
              font-normal
              text-center
              transform
              text-shadow
              tracking-normal
            "
          >
            {{ ageTitle }}
          </p>
        </div>
        <div class="rounded-full shadow-background-circle">
          <div
            class="
              w-52
              h-52
              sm:w-52 sm:h-52
              md:w-64 md:h-64
              lg:w-72 lg:h-72
              xl:w-96 xl:h-96
              2xl:w-104 2xl:h-104
              p-5
              background-circle
              rounded-full
              flex flex-col
              justify-center
              items-center
            "
          >
            <img
              :src="imgAge"
              class="w-28 md:w-48 lg:w-48 xl:w-60 2xl:w-104"
              alt=""
            />
          </div>
        </div>
      </div>
      <!-- "Descubre mundos y flecha" -->
      <div
        class="
          w-full
          h-full
          flex flex-col
          justify-start
          mt-12
          xl:mt-16
          2xl:mt-32
          items-center
        "
      >
        <!-- <div class="h-40"></div> -->
        <div
          class="
            text-white
            w-full
            text-xl
            sm:text-sm
            md:text-2xl
            2xl:text-6xl
            text-center
            transform
            sm:translate-y-3/4
          "
        >
          <p>Descubre otros mundos</p>
        </div>
        <div class="w-3/4 h-1/6 flex justify-center items-center">
          <img
            class="object-contain w-40 xl:w-52 2xl:w-96 h-full"
            src="../../assets/iconos/flechasDireccionales.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <!-- MUNDO -->
    <div class="mundoHidden absolute z-0 w-full h-full">
      <!-- h-0 y h-full -->
      <div class="w-full h-1/2 xl:h-1/5"></div>
      <img
        class="
          w-full
          xl:h-full
          transform
          -translate-y-15
          xl:-translate-y-0
          md:bg-top
          lg:bg-center
          xl:bg-top
        "
        :src="world"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import ArcText from "arc-text";

export default {
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  props: {
    clouds: {
      type: String,
      default: "./img/background/cloud2.png",
    },
    idCurve: {
      type: String,
      default: "000",
    },
    world: {
      type: String,
      default: "./img/worlds/worldg1.png",
    },
    isSkyStarts: {
      type: Boolean,
      default: false,
    },
    isButonNotStar: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "./img/worlds/textwr1.png",
    },
    star: {
      type: String,
      default: "./img/worlds/start1.png",
    },
    imgAge: {
      type: String,
      default: "../../assets/animals/familiaZac.png",
    },
    ageTitle: {
      type: String,
      default: "",
    },
  },
  methods: {},
  mounted() {
    // Continuar
    let curve = document.getElementById(this.idCurve);
    for (let i = 0; i <= curve.length; i++) {
      const arcText = new ArcText(curve);
      let size = 0;

      this.windowWidth <= 374
        ? (size = 200)
        : this.windowWidth < 820
        ? (size = 210)
        : this.windowWidth < 1025
        ? (size = 300)
        : this.windowWidth > 1025
        ? (size = 700)
        : (size = 600);

      arcText.arc(size);
      window.onresize = () => {
        this.windowWidth = window.innerWidth; // asigno a la propiedad el valor de el ancho de la ventana
      };
    }
  },
};
</script>

<style>
.cloudmiddle-top {
  /* background-image: url('../../assets/fondos/cloud2.png'); */
  background-size: cover;
  background-repeat: no-repeat;
}
.star-title {
  /* background-image: url('../../assets/fondos/cloud2.png'); */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}



@media (min-height: 909px)  {

  .xl909{
  transform:translateY(7%) !important;
  }
}

.btn-vamos {
  background: linear-gradient(153.43deg, #fecb00 16.67%, #ffb636 100%);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
}
.background-worldgame-1 {
  /* background-image: url('../../assets/fondos/worldg1.png'); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.background-starts {
  background-image: url("../../assets/fondos/starts.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
</style>