import { createStore } from 'vuex'
import MenusPagesModule from "./storeModules/MenusPagesModule";
import gameOne from './storeModules/gameOne';
import gameThree from './storeModules/gameThree';
import gameTwo from './storeModules/gameTwo'
import apiCalls from './storeModules/apiCalls';
import cms from './storeModules/cms';
export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    MenusPagesModule,
    gameOne,
    gameTwo,
    gameThree,
    apiCalls,
    cms
  }
})
