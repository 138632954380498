import router from "../../router"

// Atention: in this component levels is = casa de campo, salon de juegos
// and world is a diferents games inside the levels
export default {
    state: {        
        leveSelected:{},
        actualScene:0,
        dataWorld:{},
        pointsActualWorld:0,
        isWorldWin: false,
        // messageWin: false,
    },
    mutations: {
        setLeveSelected(state,leve){
          const dat = JSON.parse(JSON.stringify(leve))
          state.leveSelected = dat
        },
        setDataWorld(state,data){
            const dat = JSON.parse(JSON.stringify(data))
            state.dataWorld = dat
        },
        setIsWorldWin(state,isWin){
            state.isWorldWin = isWin
        },
        // setIsMessageWin(state,isWin){
        //     state.messageWin = isWin
        // },
        setActualScene(state,leve){
            state.actualScene = leve
        }
    },
    actions: {
        onSelectInitScene({commit},leve){
            commit('setActualScene',leve)
        },
        onClearGameThree({commit}){
            commit('setActualScene',0)
        },
        defaultInfoGameThree({commit},leve){
            commit('setLeveSelected',leve)
        },
        onSelectedLeveGameThree({commit,getters},leve){
            commit('setDataWorld',leve.scenes[getters.getActualScene])
        },
        onRotateTuberia({ commit, getters }, { row, column }) {
            // let mesageWin = getters.getMesage;
            const sceneBefore = getters.getDataWorld
            const rotateDegress = sceneBefore.positions[row][column].actualPosition +=90
            if(rotateDegress >= 360){
                sceneBefore.positions[row][column].actualPosition =0 
            }
            else{
                sceneBefore.positions[row][column].actualPosition = rotateDegress 
            }
            let countPoints =0
            for (let  x= 0; x < 8; x++) {
                for (let y = 0; y < 5; y++) {
                    const isWinPosition =  sceneBefore.positions[x][y].winPosition.filter(
                        position => position===sceneBefore.positions[x][y].actualPosition)
                   if(isWinPosition.length >= 1 ){
                       countPoints++
                   }
                }
            }
            // Si el total de puntos acumulados es estrictamente igual a 40  el juego se gana y mandamos a la pantalla de game-tuberias-win
            if(countPoints === 40 ){
                commit('setIsWorldWin', true)
                // commit('setIsMessageWin', true)
                // Antes de hacer el push a la nueva ruta esperamos 4 segundos para que pueda ver el mensaje de win
                setTimeout(() => {  
                    // commit('setIsMessageWin', false)
                    commit('setIsWorldWin', false)
                    router.push('/game-tuberias-win')
                  }, 2000);
            }
            // commit('setIsWorldWin', false)
            commit('setDataWorld',sceneBefore)
        },
        onNextWorld({commit,getters}){
            if(getters.getActualScene === getters.getLeveSelectedGameThree.numerWorlds-1 ){
                commit('setActualScene',0)
                commit('setDataWorld',{})
                
                router.push('/leve-selector')
            }
            else{
                commit('setActualScene',getters.getActualScene+1)
                commit('setDataWorld',getters.getLeveSelectedGameThree.scenes[getters.getActualScene])
                router.push('/game-tuberias')
            }
        },
        onRepeatWorld({commit,getters}){
            commit('setDataWorld',getters.getLeveSelectedGameThree.scenes[getters.getActualScene])
            router.push('/game-tuberias')
        }
    },
    getters:{
        getLeveSelectedGameThree : state => {
            const data = JSON.parse(JSON.stringify( state.leveSelected))
            return data
        },
        getActualScene : state => state.actualScene,
        getDataWorld : state => state.dataWorld,
        getIsWorldWin: state => state.isWorldWin,
        getPointsActualWorld: state => state.points,
        // getMessage : state => state.messageWin
    },
  }
  